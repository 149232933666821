import React, {useState, useEffect, useRef,} from 'react'
import Edit_type from '../index/support/edit_type';
import config from '../../config.json'
import './css.css'

const supString = sessionStorage.getItem('sup_team');
const usersupT = JSON.parse(supString);

export default function Edit(props) {
    const [data, set_data] = useState([])
    const [update, set_update] = useState(0)
    const [old_data, set_old_data] = useState()
    const ref = useRef({})
    const [send_to_display, set_send_to_display] = useState([...Array(20).fill(true, 0, 20)])
    const [help_ck, set_help_ck] = useState([...Array(20).fill(false, 0, 20)])
    const [deli_to, set_deli_to] = useState([...Array(20).fill("", 0, 20)])
    const [deli_date, set_deli_date] = useState([...Array(20).fill("", 0, 20)])
    const [search_text, set_search_text] = useState(null)
    const [deli_to_change, set_deli_to_change] = useState([...Array(20).fill(false, 0, 20)])
    const [deli_date_change, set_deli_date_change] = useState([...Array(20).fill(false, 0, 20)])
    const [deli_sta, set_deli_sta] = useState(false)


    const style_label_normal = {color: "#000", fontWeight: "normal"}


    useEffect(() => {
        //props.return_value(type, status, to, name, car)
        load()
        if(!props.all_chang)
            props.func_data(data, deli_to, deli_date, help_ck, deli_sta)

    }, [update, props.search, props.all_chang])

    const load = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        const url = config.api_url+'?mode=view_sub_detail&head_id='+props.head_id+'&depart='+userToken+"&search="+props.search
        //console.log(url)
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data_load) => {
            set_old_data(data_load)
            var get_id = [...Array(data_load.length)]
            for(var index = 0; index < data_load.length; index++){
                get_id[index] = {
                    subid: data_load[index].sub_id,
                    to: [...Array(25)]
                }
                get_id[index].to.fill(0,0,25)
            }

            if(search_text != props.search){
                set_data(get_id)
                set_search_text(props.search)
            }
            //set_data(data_load)
            ref.current = data_load
        });
    }

    const display_type = (type, arr) => {

        return(
            <>
                {
                    props.depart != 2 || props.depart == 2 && (props.supTeam != usersupT || old_data[arr].status == 4)
                        ? <div className='type' style={{backgroundColor: config.type_color[type], color: "#fff"}}>
                            {
                                config.type[type]
                            }
                        </div>
                    : <Edit_type type={type} func_type={(new_type) => {
                        var old = [...data]
                        if(old[arr])
                            old[arr].type = new_type
                        else
                            old[arr] = {type: new_type}
                        set_data(old)
                        set_update(pre => pre+1)

                    }}/>
                }
            </>
        )
    }
    
    const select_status = (status, arr) => {
        if(old_data[arr].status == 4){
            return(
                <div style={{textAlign: "center"}}>
                    เสร็จสิ้น
                </div>
            )
        }
        else if(old_data[arr].status == 20 && props.depart == 2)
            return(
                <div style={{textAlign: "center"}}>
                    ส่งต่อผู้เกี่ยวข้อง
                </div>
            )
        else
        return(
            <>
                <select disabled={props.all_chang} value={data[arr].status ? data[arr].status : status} style={{textAlign: "center", width: "100%"}} onChange={
                    e => {
                        var old = data
                        if(old[arr])
                            old[arr].status = e.target.value
                        else
                            old[arr] = {status: e.target.value}
                        set_data(old)
                        set_update(pre => pre+1)        
                }}>
                    {
                        Object.entries(config.status).map(val => {
                            if(props.depart == 2 && val[0] >= 8 && val[0] <= 10 && old_data[arr].status != 3 || (props.depart >= 8 && props.depart <= 10) && val[0] >= 5 && val[0] <= 7 && old_data[arr].status != 3 )
                                return(
                                    <option value={val[0]}>{val[1]}</option>
                                )
                            if(old_data[arr].status != 3 && val[0] == 4 || val[0] == 0) return
                            if((props.depart < 8 || props.depart > 10) && (val[0] >= 5 && val[0] <= 7)) return

                            if(old_data[arr].status > 1 && val[0] == 1 || old_data[arr].status > 2 && val[0] == 2 ) return;
                            if(old_data[arr].status > 2 && val[0] >= 5  && (props.depart < 4 || props.depart > 5)) return;
                            if(val[0] >= 8 && val[0] <= 10 && props.depart != 2) return;
                            if(val[0] == 11 && (props.depart < 4 || props.depart > 5)) return;
                            if(val[0] >= 12) return;
                            if(val[0] == 4 && props.depart == 2)
                                return(
                                    <option value={20}>{config.status[20]}</option>
                                )
                            return(
                                <option value={val[0]}>{val[1]}</option>
                            )
                        })
                    }
                    {
                        props.depart == 4 || props.depart == 5
                        ? <option value="25">ไม่เกี่ยวข้อง</option>
                        : <></>
                    }
                </select>
                
                    {
                        data[arr].status && data[arr].status == 4 && old_data[arr].status != 4 && props.depart != 22 || data[arr].status && data[arr].status == 20 && props.depart == 2
                        ? send_to(arr)
                        : <></>
                    }
                    {
                        props.depart >= 8 && props.depart <= 10 && status != 3
                        ? <>
                            <label style={style_label_normal}>ให้แผนกอื่นช่วย
                                <input type="checkbox" onChange={e => {
                                    var help_old = help_ck;
                                    help_old[arr] = e.target.checked;
                                    set_help_ck(help_old)
                                    set_update(old => old+1)
                                }}/>
                            </label>
                            {
                                print_help(arr)
                            }
                        </>
                        : <></>
                    }
                    {
                        props.depart == 19 && (data[arr].status == 3 || old_data[arr].status == 3)
                        ? delivery_car(arr)
                        : <></>
                    }
            </>
        )
    }

    const delivery_car = (arr) => {
        return(
            <>
            <label style={style_label_normal}>รถในการจัดส่ง</label>
            <select style={{width: "100%"}} defaultValue={data[arr].car ? data[arr].car : old_data[arr].car} onChange={e => {
                var old = data
                old[arr].car = e.target.value
                set_data(old)
                set_update(pre => pre+1)
            }}>
                <optgroup label="ภายในบริษัท"></optgroup>
                {
                    Object.entries(config.car.number).map((arr,i) => {
                        return(
                            <option value={i}>{arr[1]}</option>
                        )
                    })
                }
                <optgroup label="บริษัทจัดส่ง"></optgroup>
                {
                    Object.entries(config.car.company).map((arr,i) => {
                        return(
                            <option value={i+config.car.number.length}>{arr[1]}</option>
                        )
                    })
                }
                <optgroup label="บุคคล"></optgroup>
                {
                    Object.entries(config.car.person).map((arr,i) => {
                        return(
                            <option value={i+config.car.number.length+config.car.company.length}>{arr[1]}</option>
                        )
                    })
                }
            </select>
            </>
        )
    }

    const print_help = (arr) => {
        if(help_ck[arr])
        return(
            <>
                <div style={{width: "100%", overflowY: "scroll", height: "75px"}}>
                <div>ส่งไปยัง</div>
                {
                    Object.entries(config.user_depart).map(val => {
                        if(props.depart == val[0]) return;
                        if(val[0] >= 8 && val[0] <= 10)
                        return(
                            <p style={{margin: "0", padding: "0"}}>
                                <label style={{color: "#000", fontWeight: "normal"}}>
                                    <input type="checkbox" value={parseInt(val[0])-1} onChange={e => update_to(e.target.value, e.target.checked, arr)}/>
                                    {val[1]}
                                </label>
                            </p>
                        )
                    })
                }
            </div>
            </>
        )
    }

    const update_to = (val, ck, arr) => {
        var old = data
        if(ck)
            old[arr].to[val] = 1
        else
            old[arr].to[val] = 0
        set_data(old)
        set_update(pre => pre+1)
    }

    const send_to = (arr) => {

        return(
            <div style={{width: "100%", overflowY: send_to_display[arr] ? "scroll" : "auto", height: send_to_display[arr] ? "100px" : "16px"}}>
                <div style={{cursor: "pointer"}} onClick={()=>{
                    var old_send_to = send_to_display
                    old_send_to[arr] = !old_send_to[arr]
                    console.log(old_send_to[arr])
                    set_send_to_display(old_send_to)
                    set_update(old => old+1)
                }}>ส่งไปยัง {send_to_display[arr] ? <>&or;</> : <>&and;</>}</div>
                {
                    props.depart == 3 || props.depart == 4 || props.depart == 5 || props.depart >= 19
                    ? <p style={{margin: "0", padding: "0", visibility: send_to_display[arr] ? "visible" : "hidden"}}>
                        <label style={{color: "#000", fontWeight: "normal"}}>
                            <input type="checkbox" value="0" onChange={e => update_to(e.target.value, e.target.checked, arr)}/>
                            ไม่ส่งไปแผนกอื่น
                        </label>
                    </p>
                    : <></>
                }
                {
                    props.depart == 4 || props.depart == 5
                    ? <p style={{margin: "0", padding: "0", visibility: send_to_display[arr] ? "visible" : "hidden"}}>
                        <label style={{color: "#000", fontWeight: "normal"}}>
                            <input type="checkbox" value="24" onChange={e => update_to(e.target.value, e.target.checked, arr)}/>
                            ส่งกลับ Support team
                        </label>
                    </p>
                    : <></>
                }
                {
                    Object.entries(config.user_depart).map(val => {
                        if(props.depart == val[0] || props.depart == 22) return;
                        if(val[0] >= 3 && val[0] <= 22)
                        return(
                            <p style={{margin: "0", padding: "0", visibility: send_to_display[arr] ? "visible" : "hidden"}}>
                                <label style={{color: "#000", fontWeight: "normal"}}>
                                    <input type="checkbox" value={parseInt(val[0])-1} onChange={e => update_to(e.target.value, e.target.checked, arr)}/>
                                    {val[1]}
                                </label>
                            </p>
                        )
                    })
                }
            </div>
        )
    }

    const sup_edit_name = (name,arr) => {
        if(old_data[arr].status == 4)
            return(
                <div>{(parseInt(arr)+1)}. {name}</div>
            )
        
        return(
            <>
                {(parseInt(arr)+1)}. 
                <input style={{width: "80%"}} type="text" value={data[arr].subname ? data[arr].subname : name} onChange={e => {
                    var old = data
                    if(old[arr])
                        old[arr].subname = e.target.value
                    else
                        old[arr] = {subname: e.target.value}
                    set_data(old)
                    set_update(pre => pre < 8 ? pre+1 : 0)
                }}/>
            </>
        )
    }

    const del = (id) => {
        if((props.depart != 2 || props.depart == 2 && props.len == 1) || props.viewer == 1 || props.head_len == 1 && old_data.length == 1) return
        return(
            <button className='btn_del' onClick={(e) => delete_data(e ,id)}>ลบข้อมูล</button>
        )
    }

    const delete_data = (e, id) => {
        e.preventDefault()
        if(!window.confirm("คุณแน่ใจหรือไม่ที่จะลบข้อมูลนี้"))
            return;
        const send = {
            mode: "0",
            id: id
        }
        console.log(send)
        fetch(config.api_url, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(send)
        })
        .then((response) => response.json(send))
        .then((data) => {
            window.location.reload()
        });
    }

    const deli_detail_display = (deli_to_f, deli_date_f, arr) => {
        const label_style = {textAlign: "left"}
        if(props.deli_sta == 2 && props.depart == 2)
            return(
                <>
                <div style={{gridColumn: "1/3"}}></div>
                <div style={{gridColumn: "3/5", display: "grid", gridTemplateColumns: "100px 1fr", gridRowGap: "5px"}}>
                    <label style={label_style}>ส่งของวันที่</label>
                    <input type="date" value={deli_date_change[arr] ? deli_date[arr] : deli_date_f} onChange={e => {
                        var old = deli_date
                        old[arr] = e.target.value

                        var old_status_date = deli_date_change
                        old_status_date[arr] = true
                        set_deli_date_change(old_status_date)

                        set_deli_date(old)
                        set_update(old => old+1)
                        set_deli_sta(true)
                    }} />
                    <label style={label_style}>สถานที่ส่ง</label>
                    <input value={deli_to_change[arr] ? deli_to[arr] : deli_to_f} type="text" onChange={e => {
                        var old = deli_to
                        old[arr] = e.target.value

                        var old_status_to = deli_to_change
                        old_status_to[arr] = true
                        set_deli_to_change(old_status_to)

                        set_deli_to(old)
                        set_update(old => old+1)
                        set_deli_sta(true)
                    }}/>
                </div>
                <div></div>
            </>
            )

        if(deli_to_f != 0 && props.depart == 2 && old_data[arr].status != 4)
        return(
            <>
                <div style={{gridColumn: "1/3"}}></div>
                <div style={{gridColumn: "3/5", display: "grid", gridTemplateColumns: "100px 1fr", gridRowGap: "5px"}}>
                    <label style={label_style}>ส่งของวันที่</label>
                    <input type="date" value={deli_date_change[arr] ? deli_date[arr] : deli_date_f} onChange={e => {
                        var old = deli_date
                        old[arr] = e.target.value

                        var old_status_date = deli_date_change
                        old_status_date[arr] = true
                        set_deli_date_change(old_status_date)

                        set_deli_date(old)
                        set_update(old => old+1)
                    }} />
                    <label style={label_style}>สถานที่ส่ง</label>
                    <input value={deli_to_change[arr] ? deli_to[arr] : deli_to_f} type="text" onChange={e => {
                        var old = deli_to
                        old[arr] = e.target.value

                        var old_status_to = deli_to_change
                        old_status_to[arr] = true
                        set_deli_to_change(old_status_to)

                        set_deli_to(old)
                        set_update(old => old+1)
                    }}/>
                </div>
                <div></div>
            </>
        )
        if(deli_to_f != 0)
        return(
            <>
                <div style={{gridColumn: "1/3"}}></div>
                <div style={{gridColumn: "3/5", display: "grid", gridTemplateColumns: "100px 1fr", gridRowGap: "5px"}}>
                    <label style={label_style}>ส่งของวันที่</label>
                    <span>{deli_date_f}</span>
                    <label style={label_style}>สถานที่ส่ง</label>
                    <span>{deli_to_f}</span>
                </div>
                <div></div>
            </>
        )
    }

    if(!old_data)
        return(
            <div>
                loading...
            </div>
        )
    if(old_data[0].none || data[0].none)
            return(
                <div style={{gridColumn: "1/5", textAlign: "center"}}>none</div>
            )
    return(
        <>
            {
                Object.entries(old_data).map(val => {

                    return(
                        <>
                            <div key={"d1"+val[0]} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginLeft: "25px"}}>
                                {
                                    props.depart != 2
                                    ? <>{(parseInt(val[0])+1)}. {val[1].subname}</>
                                    : sup_edit_name(val[1].subname,val[0])
                                }
                            </div>
                            <div key={"d2"+val[0]} style={{textAlign: "center"}}>
                                {
                                    val[1].status == 1 || val[1].status == 2
                                    ? "none"
                                    : val[1].start
                                }
                                -
                                {
                                    val[1].end != "-"
                                    ? val[1].end
                                    : "none"
                                }
                            </div>
                            <div key={"d3"+val[0]}>
                                {
                                    display_type(val[1].type, val[0])
                                }
                            </div>
                            <div key={"d4"+val[0]}>
                                {
                                    select_status(val[1].status, val[0])
                                }
                            </div>
                            <div key={"d5"+val[0]}>
                                {del(val[1].sub_id)}
                            </div>
                            {
                                deli_detail_display(val[1].deli_to, val[1].deli_date, val[0])
                            }
                        </>
                    )
                })
            }
        </>
    )
}