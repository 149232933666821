import React, {useState} from "react"

export default function Deli_to(props){
    const [popup, set_popup] = useState(false)

    function getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }
    function getHeight() {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        );
    }
    function disable(){
        document.body.classList.add('disable_scroll');
    }

    function enable(){
        document.body.classList.remove('disable_scroll');
    }
    
    return(
        <>
            <span className='job_name' style={{color: "#2ce4fa", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", width: "100%"}} onClick={() => {set_popup(true); disable(); }}>{props.text}</span>
            {
                popup
                ? <><div className='Add_bg' style={{top: window.scrollY, width: getWidth(), height: getHeight()}} onClick={ () => {set_popup(false); enable();}}></div>
                    <div className='Add' style={{color: "#000",backgroundColor: "#E9F6FE",top: (window.scrollY+(window.innerHeight*0.5)), left: (window.scrollX+(window.innerWidth*0.5)), translate: "-50% -50%", padding: "25px"}}>
                        <h3 style={{lineBreak: "anywhere"}}>{props.name}</h3>
                        <p style={{lineBreak: "anywhere"}}>{props.text}</p>
                </div></>
                : <></>
            }
        </>
    )
}