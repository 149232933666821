import React, {useState, useEffect} from 'react'
import config from '../../../config.json'

export default function Edit_type(props){
    const [type ,set_type] = useState(props.type)

    useEffect(() => {
        props.func_type(type)
    }, [type])

    return(
        <div className='type'>
            <select className='type_select' style={{border: "none", borderRadius: "3px", textAlign: "center", backgroundColor: config.type_color[type], color: type != 0 ? "#fff" : "#000"}} defaultValue={type} onChange={val => set_type(val.target.value)}>
                {
                    Object.entries(config.type).map(val => {
                        if(val[0] == 0) return;
                        return(
                            <option style={{textAlign: "center", backgroundColor: config.type_color[val[0]], color: val[0] == 0 ? "#000" : "#fff"}} value={val[0]}>{val[1]}</option>
                        )
                    })
                }
            </select>
        </div>
    )
}