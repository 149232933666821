import React, {useState, useEffect, useRef} from 'react'
import Viewer_sub from './viewer_sub'
import Add from '../support/add'
import Deli_to from './viewer_deli_to'
import config from '../../../config.json'
import './css.css'
import AutoScroll from '../../middleware/autoScroll'

const idString = sessionStorage.getItem('sup_team');
const sup_team = JSON.parse(idString);

export default function Viewer(props){
    const [data, set_data] = useState()
    const [popup, set_popup] = useState(false)
    const [filter_date, set_filter_date] = useState(false)
    const [page, set_page] = useState(0)
    const click_ck = useRef([])
    const search_text = useRef("")
    const stop_load = useRef(false)
    const [render, set_render] = useState(0)
    const [text_time, set_text_time] = useState("")
    const [type_filter, set_type_filter] = useState(0)

    const type_color = config.type_color
    const text_color = ["#fff", "#0E2D87"]

    /* document.getElementById("root").addEventListener("mousemove", (event) => {

    }); */

    AutoScroll()

    useEffect(() => {
        const load_timout = setTimeout(() => {
            set_text_time_func()
            load()
        }, 250)
        const interval = setInterval(() => {
            if(!stop_load.current)
                load()
        }, 10000)
        const interval_time = setInterval(() => {
            set_text_time_func()
        }, 900)
        return () => {
            clearInterval(interval);
            clearInterval(interval_time);
            clearInterval(load_timout);
        }
    }, [page, type_filter])

    const set_text_time_func = () => {
        var currentdate = new Date();
        var text = (Math.floor(currentdate.getHours()/10)).toString()+(currentdate.getHours()%10) + ":"
                + (Math.floor(currentdate.getMinutes()/10)).toString()+(currentdate.getMinutes()%10) + ":"
                + (Math.floor(currentdate.getSeconds()/10)).toString()+(currentdate.getSeconds()%10) + " น. "
                + currentdate.getDate() + "/" + (currentdate.getMonth()+1) + "/" + currentdate.getFullYear()
        set_text_time(text)
    }

    const load = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        const url = config.api_url+'?mode=view&page='+page+"&search="+search_text.current+"&depart="+(props.depart < 99 ? userToken : props.depart)+"&supTeam="+sup_team+"&type="+type_filter
        //console.log(url)
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            //console.log(data)
            set_data(data)
            set_render(pre => pre+1)
        });
    }

    const load_type_filter = (type) => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        const url = config.api_url+'?mode=view&page='+page+"&search="+search_text.current+"&depart="+(props.depart < 99 ? userToken : props.depart)+"&supTeam="+sup_team+"&type="+type
        //console.log(url)
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            //console.log(data)
            set_data(data)
            set_render(pre => pre+1)
        });
    }

    const next_page = () => {
        var bg = {backgroundColor: '#9ec4ff'}
        const style_nml = {bottom: "1rem", right: "1rem", position: "absolute"}
        const style_lng = {textAlign: "right"}
        const style_page = window.screen.height < document.body.offsetHeight ? style_lng : style_nml
        if(typeof data == "undefined" || !data.page)
            return(<></>)
        return(
            <div className='next_per_page' style={style_page}>
                <button style={bg} onClick={() => set_page(old => parseInt(old)-9 < 0 ? 0 : parseInt(old)-9)}>&#60;&#60;</button>
                {
                    Object.entries([...Array(data.page.page)]).map(i => {
                        var bg = {backgroundColor: '#9ec4ff'}
                        if(i[0] == page) bg = {backgroundColor: '#819dca'}
                        var morepage = parseInt(page)+3
                        var lesspage = parseInt(page)-3
                        if(i[0] > lesspage && i[0] < morepage || i[0] == 0 || i[0] == data.page.page-1)
                            return(
                                <>
                                    {
                                        page < data.page.page-1 && i[0] == data.page.page-1 && data.page.page > morepage
                                        ? <p key={"pnp1"+i[0]} style={{display: "inline", padding: "0 0.25rem"}}>...</p>
                                        :  <></>
                                    }
                                    <button key={"bnp"+i[0]} style={bg} onClick={() => {set_page(i[0]); window.scrollTo(0,0)}}>{(parseInt(i[0])+1)}</button>
                                    {
                                        page > 2 && i[0] == 0
                                        ? <p key={"pnp2"+i[0]} style={{display: "inline", padding: "0 0.25rem"}}>...</p>
                                        : <></>
                                    }
                                </>
                            )
                        return <></>;
                    })
                }
                <button style={bg} onClick={() => set_page(old => parseInt(old)+9 >= data.page.page ? data.page.page-1 : parseInt(old)+9)}>&#62;&#62;</button>
            </div>
        )
    }
    function Text_note(note, name, pos){
        function getWidth() {
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            );
        }
        function getHeight() {
            return Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.documentElement.clientHeight
            );
        }
        function disable(){
            document.body.classList.add('disable_scroll');
        }

        function enable(){
            document.body.classList.remove('disable_scroll');
        }

        return(
            <>
                <div className='job_name' onClick={() => {set_popup(true); click_ck.current[pos] = true;}}>*คลิก</div>
                {
                    click_ck.current[pos]
                    ? <><div className='Add_bg' style={{top: window.scrollY, width: getWidth(), height: getHeight()}} onClick={ () => {set_popup(false); click_ck.current[pos] = false; enable();}}></div>
                        <div className='Add' style={{top: (window.scrollY+(window.innerHeight*0.5)), left: (window.scrollX+(window.innerWidth*0.5)), translate: "-50% -50%"}}>
                            <div style={{color: "#000", padding: "1rem"}}>
                                <h3>{name}</h3>
                                <p>
                                    {note}
                                </p>
                            </div>
                    </div></>
                    : <></>
                }
            </>
        )
    }

    function withoutProperty(obj, property) {  
        const { [property]: unused, ...rest } = obj
        var arr = Object.keys(rest).map(function(k) { return obj[k] });
        return arr
    }

    const table_row = () => {
        if(!data[0])
            return(
                <tbody>
                    <tr>
                        <td style={{textAlign: "center"}} colSpan={13}>none</td>
                    </tr>
                </tbody>
            )

        var no_page = withoutProperty(data, 'page')
        if(filter_date){
            no_page.sort((a, b) => a.deli_raw - b.deli_raw)
        }

        return(
            <tbody>
                {
                    Object.entries(no_page).map(val => {

                        return(
                            <tr key={"trd"+val[1].id}>
                                {
                                    val[1].emg != 1
                                    ? <>
                                        <td colSpan={4}>
                                            <Viewer_sub deli_sta={val[1].deli_sta} supTeam={val[1].sup_team} stop_load={(val)=> stop_load.current = val} load={()=> load()} name={val[1].name} main_id={val[1].id} depart={props.depart} date_raw={val[1].date_raw} date_deli={val[1].deli_raw} deli_to={val[1].deli_to} note={val[1].note} care={val[1].care}/>
                                        </td>
                                        <td>
                                            <p>{val[1].date_raw}</p>
                                        </td>
                                    </>
                                    : <>
                                        <td colSpan={3}>
                                            <Viewer_sub deli_sta={val[1].deli_sta} supTeam={val[1].sup_team} stop_load={(val)=> stop_load.current = val} load={()=> load()} name={val[1].name} main_id={val[1].id} depart={props.depart} date_raw={val[1].date_raw} date_deli={val[1].deli_raw} deli_to={val[1].deli_to} note={val[1].note} care={val[1].care}/>
                                        </td>
                                        <td>
                                            <p>{val[1].date_raw}</p>
                                        </td>
                                        <td>
                                            <p className='blink_me' style={{backgroundColor: "red", padding: "0", borderRadius: "3px", textAlign: "center", width: "85px", animationDuration: "3s"}}>
                                                Urgent !!!!!
                                            </p>
                                        </td>
                                    </>
                                }
                                <td>
                                    <div className='status_detail'>
                                        <div style={{backgroundColor: "#52C3F1", color: text_color[1]}}>
                                            S{val[1].sup_team}
                                        </div>
                                        <div style={{backgroundColor: val[1].detail == 1 && val[1].type != 1 ? "#93C21E" : val[1].type == 20 ? "#52C3F1" : val[1].type == 10 ? '#E84166' : type_color[val[1].type]}}>
                                            {
                                                val[1].detail == 1 && props.depart != 2 || props.depart == 2 && val[1].type != 1 && val[1].detail == 1 || val[1].type == 20
                                                ? 'เสร็จสิ้น'
                                                : val[1].type == 10
                                                    ? 'รอบางส่วน'
                                                    : config.type[val[1].type]
                                            }
                                        </div>
                                        <div style={{backgroundColor: "#1A77BC", color: text_color[1]}}>{val[1].count}</div>
                                    </div>
                                </td>
                                <td key={"tdd"+val[1].id} colSpan={4}>
                                    {
                                        val[1].type == 20
                                        ? 'เสร็จสิ้น'
                                        : val[1].detail[0] && val[1].detail[0].status == 0 && !(props.depart == 99 || props.depart == 100)
                                            ? props.depart == 2
                                                ? 'ส่งต่อผู้เกี่ยวข้อง'
                                                : 'เสร็จสิ้น'
                                            : table_row_detail(val[1].detail, val[0])
                                    }
                                </td>
                                <td colSpan={2} style={{textAlign: "center", color: "#FEEF00", fontSize: "12px"}}>
                                    {
                                        val[1].deli_sta == 0

                                        ? <>
                                            {val[1].date}
                                            <br/>{/* <span style={{color: "#2ce4fa", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", width: "100%"}}>{val[1].deli_to}</span> */}
                                            <Deli_to name={val[1].name} text={val[1].deli_to} status={val[1].deli_sta}/>
                                        </>
                                        : <>
                                            ไม่กำหนด
                                        </>
                                    }
                                </td>
                                <td style={{textAlign: "center"}}>
                                    {
                                        val[1].note != ""
                                        ? Text_note(val[1].note, val[1].name, val[0])
                                        : "-"
                                    }
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        )
    }

    const color_status_1 = (stat, ts) => {
        var color_status1 = "#52C3F1"
        if(stat == 1){
            const unix = Math.floor((new Date()).getTime() / 1000)
            if(unix > ts+config.timestamp_stat1.time[2]){
                color_status1 = config.timestamp_stat1.color[2]
            }
            else if(unix > ts+config.timestamp_stat1.time[1]){
                color_status1 = config.timestamp_stat1.color[1]
            }
            else if(unix > ts+config.timestamp_stat1.time[0]){
                color_status1 = config.timestamp_stat1.color[0]
            }
        }
        return color_status1
    }
    
    const table_row_detail = (detail, id) => {

        const line_width = document.querySelector('.textSlide_box') ? document.querySelector('.textSlide_box') : 0
        const textwidth = document.querySelector('#slide'+id) ? document.querySelector('#slide'+id) : 0
        const slide_class = props.depart == 99 || props.depart == 100 ? textwidth.scrollWidth > line_width.clientWidth ? "textSlide" : "" : ""
        return(
            <div className='textSlide_box'>
                <div className={slide_class} id={"slide"+id} style={{whiteSpace: slide_class == "" ? "nowrap" : ""}}>
                {
                    Object.entries(detail).map(val => {
                        const detail_color= "#52C3F1"

                        return(
                            <>
                                { props.depart == 99 || props.depart == 100
                                    ? <><span>
                                     &nbsp;&gt;&nbsp;{config.user_depart[val[0]]}
                                        : <span style={{color: color_status_1(val[1].status, val[1].status1_timestamp)}}>
                                            <span className='blink_me'>
                                            {config.status[val[1].status]}
                                            </span>
                                        </span>
                                        {
                                            val[1].car
                                            ? <span style={{color: detail_color}}>
                                                &rarr;
                                                {
                                                    val[1].car <= 8
                                                    ? config.car.number[val[1].car]
                                                    : val[1].car <= 14
                                                        ? config.car.company[parseInt(val[1].car)-9]
                                                        : val[1].car != 99
                                                            ? config.car.person[parseInt(val[1].car)-15]
                                                            : " หลายคัน"
                                                }
                                            </span>
                                            : ""
                                        }
                                    </span>
                                    </>
                                    : <>
                                            <div key={"dd"+val[0]} style={{color: color_status_1(val[1].status, val[1].status1_timestamp)}}>
                                                {
                                                    config.status[val[1].status]
                                                }
                                                {
                                                    val[1].car && val[1].status != config.status_value.finish
                                                    ? <>
                                                        {
                                                            val[1].car <= 8
                                                            ? " "+config.car.number[val[1].car]
                                                            : val[1].car <= 14
                                                                ? " "+config.car.company[parseInt(val[1].car)-9]
                                                                : val[1].car != 99
                                                                    ? config.car.person[parseInt(val[1].car)-15]
                                                                    : " หลายคัน"
                                                        }
                                                    </>
                                                    : ""
                                                }
                                            </div>
                                    </>
                                }
                            </>
                        )
                    })
                }
                {
                    textwidth.scrollWidth > line_width.clientWidth
                    ? <span style={{marginLeft: "10%"}}></span>
                    : <></>
                }
                {
                    Object.entries(detail).map(val => {
                        const detail_color= "#52C3F1"
                        if(textwidth.scrollWidth > line_width.clientWidth)
                        return(
                            <>
                                { props.depart == 99 || props.depart == 100
                                    ? <><span key={"vs"+val[0]}>
                                     &nbsp;&gt;&nbsp;{config.user_depart[val[0]]}
                                        : <span style={{color: color_status_1(val[1].status, val[1].status1_timestamp)}}>
                                            <span className='blink_me'>
                                            {
                                                config.status[val[1].status]
                                            }
                                            </span>
                                        </span>
                                        {
                                            val[1].car
                                            ? <span style={{color: detail_color}}>
                                                {
                                                    val[1].car <= 8
                                                    ? config.car.number[val[1].car]
                                                    : val[1].car <= 14
                                                        ? config.car.company[parseInt(val[1].car)-9]
                                                        : val[1].car != 99
                                                            ? config.car.person[parseInt(val[1].car)-15]
                                                            : " หลายคัน"
                                                }
                                            </span>
                                            : ""
                                        }
                                    </span>
                                    </>
                                    : <>
                                    </>
                                }
                            </>
                        )
                    })
                }
                </div>
            </div>
        )
    }

    const table = <table className='viewer_table'>
        <colgroup>
            <col style={{width: "100px"}}/>
            <col style={{width: "100px"}}/>
            <col style={{width: "100px"}}/>
            <col style={{width: "100px"}}/>
            <col style={{width: "100px"}}/>

            <col style={{width: "160px"}}/>
            <col style={{width: "100px"}}/>
            <col style={{width: "100px"}}/>
            <col style={{width: "100px"}}/>
            <col style={{width: "100px"}}/>

            <col style={{width: "69px"}}/>
            <col style={{width: "70px"}}/>
            <col style={{width: "75px"}}/>
        </colgroup>
        <thead>
            <tr>
                <th colSpan={5} style={{textAlign: "left"}}>Job Name</th>
                <th colSpan={5} style={{textAlign: "left", border: "1px solid #52C3F1", borderStyle: "none solid"}}>
                    Status
                    
                    <div className='type_dropdown_div'>
                        <select className='type_dropdown_select' onChange={e => {
                            set_type_filter(e.target.value)
                            //console.log(e.target.value)
                            load_type_filter(e.target.value)
                        }}>
                            {
                                Object.entries(config.type).map(val => {
                                    return(
                                        <option value={val[0]}>
                                            {val[1]}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </th>
                <th colSpan={2} style={{border: "1px solid #52C3F1", borderStyle: "none solid"}}>
                    <div style={{cursor: "pointer"}} onClick={() => set_filter_date(old => !old)}>
                        Delivery Date/To
                        {
                            filter_date == 0
                            ? <span style={{opacity: "0.5"}}> &or;</span>
                            : <span style={{opacity: "1"}}> &or;</span>
                        }
                    </div>
                </th>
                <th>Note</th>
            </tr>
        </thead>
        {
            typeof data == "undefined" || data.page.len == 0
            ? <tr>
                <td colSpan={12} style={{textAlign: "center"}}>none</td>
            </tr>
            : table_row()
        }
    </table>

    const search = () => {
        return(
            <h2 style={{float: "right", padding: "0 2rem", marginBottom: "0"}}>
                <span style={{fontSize: "14px"}}>{text_time}</span>
                <input style={{margin: "0 5px", height: "21px", border: "none", padding: "0", borderRadius: "3px"}} type="text" onChange={val => {search_text.current = val.target.value; if(search_text.current == "") load()}}/>
                <button style={{borderRadius: "3px", border: "none", height: "21px"}} onClick={() => load()}>Search</button>
            </h2>
        )
    }

    const supString = sessionStorage.getItem('sup_team');
    const usersupT = JSON.parse(supString);

    return(
        <div style={{ margin: "auto", padding: "0"}}>
            <div>
                <h2 style={{padding: "0rem 2rem", marginBottom: "10px", float: "left"}}>
                    {config.user_depart[props.depart]}
                    {
                        props.depart == 2
                        ? <>
                            <span>&nbsp;{usersupT}</span>
                            <Add depart={props.depart}/>
                        </>
                        : <></>
                    }
                    {/* <AutoScroll /> */}
                </h2>
                {
                    search()
                }
            </div>
            <div style={{width: "100%", fontSize: "14px"}}>
                <div style={{padding: "1rem 2rem"}}>
                    {
                        table
                    }
                </div>
            </div>
            <div>
                {next_page()}
            </div>
        </div>
    )
}