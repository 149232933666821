import React, { useState, useEffect, useRef } from 'react'
import Admin_table from '../admin/user_table.js'
import Viewer from './viewer/viewer_page.js'
import config from '../../config.json'
import '../css/index.css'

export default function Index({ depart}){
    const [admin_selec, set_admin_selec] = useState(0)
    const nameString = sessionStorage.getItem('username');
    const username = JSON.parse(nameString);

    const style = useRef({})

    if(depart == 23)
    return (
        <div style={style.current}>
            <h2 style={{padding: "0rem 2rem"}}>{config.user_depart[depart]}</h2>
            <Admin_table />
        </div>
    )
    else
    return(
        <div style={style.current}>
            <Viewer depart={depart == 1 || depart == 24 ? depart == 24 ? 99 : 100 : depart}/>
        </div>
    )
}