import React, {useState, useEffect} from 'react'
import Edit from './edit_sub'
import config from '../../config.json'


{/* <Edit viewer={props.viewer} len={props.detail.length} supTeam={(props.supTeam) ? props.supTeam : "0"} car={val[1].detail.car ? val[1].detail.car : 0} sub={val[1]} pos={val[0]} depart={props.depart} return_value={(type, status, to, name, car) => return_value(type, status, to, name, car, val[0])}/> */}

export default function Update_status(props){

    const display_sub_detail = () => {

        return(
            <>
                {
                    Object.entries(props.detail).map(val => {
                        return(
                            <Sub_head deli_sta={props.deli_sta} head_len={props.head_len ? props.head_len : 0} search={props.search} key={"sh"+val[0]} arr={val[0]} headname={val[1].head_name} headid={val[1].head_id} viewer={props.viewer} supTeam={(props.supTeam) ? props.supTeam : "0"} depart={props.depart} func_data={(data, arr) => props.func_data(data, arr)} func_data_all={(data, arr) => props.func_data(data, arr)}/>
                        )
                    })
                }
            </>
        )
    }

    return(
        <div id="detail_form_sub_box">
                <div className='data_sub'>
                    <div style={{gridColumn: "1/3", border: "1px solid #52C3F1", borderStyle: "none none solid none", color: "#263D7D", fontWeight: "bold"}}>MEDIA</div>
                    <div style={{gridColumn: "3/6", border: "1px solid #52C3F1", borderStyle: "none none solid none", color: "#263D7D", fontWeight: "bold"}}>STATUS</div>
                    {
                        display_sub_detail()
                    }
                </div>
        </div>
    )
}

function Sub_head(props){
    const [popup, set_popup] = useState(false)
    const [status, set_status] = useState(0)
    const [status_old, set_status_old] = useState(0)
    const [to_all, set_to_all] = useState([...Array(25).fill(0, 0, 25)])
    const [all_chang, set_all_chang] = useState(false)
    const [car, set_car] = useState(0)
    const [send_to_display, set_send_to_display] = useState(true)
    const [update, set_update] = useState(0)
    const [type, set_type] = useState(0)

    useEffect(() => {
        if(status == 0)
            load()
        update_all_data()
    }, [update, all_chang])

    const load = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        const url = config.api_url+'?mode=view_stat&head_id='+props.headid+'&depart='+userToken
        //console.log(url)
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            set_type(data.type)
            set_status(data.status)
            set_status_old(data.status)
        });
    }

    const add_data_head = (data, to, date, help, deli_sta) => {
        if(!data) return;
        var json = {
            headid: props.headid,
            all: 0,
            deli_sta: deli_sta ? 1 : 0,
            detail: []
        }
        for(var index = 0; index < data.length; index++){
            var to_raw = data[index] ? data[index].to : 99
            var to_out = 0;
            if(to_raw != 99)
                for(var i = 0; i < to_raw.length; i++){
                    to_out |= to_raw[i] ? 1 << i : 0 << i
                }
            json.detail[index] = {
                subid: data[index].subid,
                status: data[index] ? data[index].status ? data[index].status : 99 : 99,
                type: data[index] ? data[index].type ? data[index].type : 99 : 99,
                subname: data[index] ? data[index].subname ? data[index].subname : 99 : 99,
                to: to_out,
                help: help[index],
                deli_to: to[index] != "" ? to[index] : null,
                deli_date: date[index] != "" ? date[index] : null
            }
            if(data[index] && data[index].car)
                json.detail[index].car = data[index].car
            if(to_raw[24] == 1)
                json.detail[index].reset = 1
        }
        props.func_data(json, props.arr)
    }

    const update_all_data = () => {
        if(!all_chang) return;

        var to_out = 0
        to_all.map((val, index) => {
            to_out |= val ? 1 << index : 0 << index
        })

        var json = {
            headid: props.headid,
            all: 1,
            type: type,
            status: status,
            to: to_out,
            car: car,
            reset: to_all[24]
        }
        //console.log(json)
        props.func_data_all(json, props.arr)
    }

    const update_all = () => {
        if(status != 0 && status_old != 4 && status_old != 20)
            return(
                <>
                    <select title='รายการย่อยต้องมีสถานะเหมือนกัน' style={{width: "150px"}} value={status} onChange={e => {set_status(e.target.value); set_update(old => old+1)}} disabled={!all_chang}>
                        {
                            Object.entries(config.status).map(val => {
                                if(val[0] == 0) return;
                                if(status_old >= 2 && val[0] == 1) return;
                                if(status_old >= 3 && val[0] <= 2) return;
                                if(status_old != 3 && val[0] == 4) return;
                                if(status_old == 3 && ([1, 2].includes(parseInt(val[0])) || val[0] > 4)) return;
                                if(props.depart != 2 && [8, 9, 10].includes(parseInt(val[0]))) return;
                                if(!([4, 5].includes(parseInt(props.depart))) && val[0] == 11) return;
                                if((props.depart < 8 || props.depart > 10) && val[0] >= 5 && val[0] <= 7) return;
                                if(val[0] >= 12) return;
                                if(val[0] == 4 && props.depart == 2)
                                    return(
                                        <option value={20}>{config.status[20]}</option>
                                    )
                                return(
                                    <option value={val[0]}>{val[1]}</option>
                                )
                            })
                        }
                        {
                            props.depart == 4 || props.depart == 5
                            ? <option value="25">ไม่เกี่ยวข้อง</option>
                            : <></>
                        }
                    </select>
                    <label>
                        <input type="checkbox" onChange={e => set_all_chang(e.target.checked)}/>
                        {
                            all_chang
                            ? 'ปิดการใช้งาน'
                            : 'เปิดการใช้งาน'
                        }
                    </label>
                    {
                        (status == 4 || status == 20 && props.depart == 2) && status_old == 3 && all_chang
                        ? send_to_all()
                        : <></>
                    }
                    {
                        status == 3 && status_old != 3 && props.depart == 19 && all_chang
                        ? delivery_car()
                        : <></>
                    }
                </>
            )
        else if(status_old == 4)
            return(
                <>
                    เสร็จสิ้น
                </>
            )
        else if(status_old == 20 && props.depart == 2){
            return(
                <>
                    ส่งต่อผู้เกี่ยวข้อง
                </>
            )
        }
        else
                return(
                    <>
                        รายการย่อยมีสถานะไม่เหมือนกัน
                    </>
                )
    }

    const send_to_all = () => {

        return(
            <div style={{width: "150px", overflowY: send_to_display ? "scroll" : "auto", height: send_to_display ? "100px" : "16px"}}>
                <div style={{cursor: "pointer"}} onClick={()=>{
                    set_send_to_display(old => !old)
                    set_update(old => old+1)
                }}>ส่งไปยัง {send_to_display ? <>&or;</> : <>&and;</>}</div>
                {
                    props.depart == 3 || props.depart == 4 || props.depart == 5 || props.depart >= 19
                    ? <p style={{margin: "0", padding: "0", visibility: send_to_display ? "visible" : "hidden"}}>
                        <label style={{color: "#000", fontWeight: "normal"}}>
                            <input type="checkbox" value="0" onChange={e => update_to(e.target.value, e.target.checked)}/>
                            ไม่ส่งไปแผนกอื่น
                        </label>
                    </p>
                    : <></>
                }
                {
                    props.depart == 4 || props.depart == 5
                    ? <p style={{margin: "0", padding: "0", visibility: send_to_display ? "visible" : "hidden"}}>
                        <label style={{color: "#000", fontWeight: "normal"}}>
                            <input type="checkbox" value="24" onChange={e => update_to(e.target.value, e.target.checked)}/>
                            ส่งกลับ Support team
                        </label>
                    </p>
                    : <></>
                }
                {
                    Object.entries(config.user_depart).map(val => {
                        if(props.depart == val[0] || props.depart == 22) return;
                        if(val[0] >= 3 && val[0] <= 22)
                        return(
                            <p style={{margin: "0", padding: "0", visibility: send_to_display ? "visible" : "hidden"}}>
                                <label style={{color: "#000", fontWeight: "normal"}}>
                                    <input type="checkbox" value={parseInt(val[0])-1} onChange={e => update_to(e.target.value, e.target.checked)}/>
                                    {val[1]}
                                </label>
                            </p>
                        )
                    })
                }
            </div>
        )
    }
    const update_to = (val, ck) => {
        var old = to_all
        if(ck)
            old[val] = 1
        else
            old[val] = 0
        set_to_all(old)
        set_update(pre => pre+1)
    }

    const type_display = () => {
        if(status != 0 && status_old != 4){
            if(props.depart == 2 && all_chang)
                return(
                    <div className='type'>
                        <select className='type_select' style={{border: "none", borderRadius: "3px", textAlign: "center", backgroundColor: config.type_color[type], color: type != 0 ? "#fff" : "#000"}} defaultValue={type} onChange={val => {set_type(val.target.value); set_update(old => old+1)}}>
                            {
                                Object.entries(config.type).map(val => {
                                    if(val[0] == 0) return;
                                    return(
                                        <option style={{textAlign: "center", backgroundColor: config.type_color[val[0]], color: val[0] == 0 ? "#000" : "#fff"}} value={val[0]}>{val[1]}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                )
            return(
                <div className='type' style={{backgroundColor: config.type_color[type], color: "#fff"}}>
                    {
                        config.type[type]
                    }
                </div>
            )
        }
    }

    const delivery_car = () => {
        return(
            <>
            <label style={{color: "#000", fontWeight: "normal"}}>รถในการจัดส่ง</label>
            <br/>
            <select style={{width: "150px"}} defaultValue={car} onChange={e => {
                set_car(e.target.value)
                set_update(pre => pre+1)
            }}>
                <optgroup label="ภายในบริษัท"></optgroup>
                {
                    Object.entries(config.car.number).map((arr,i) => {
                        return(
                            <option value={i}>{arr[1]}</option>
                        )
                    })
                }
                <optgroup label="บริษัทจัดส่ง"></optgroup>
                {
                    Object.entries(config.car.company).map((arr,i) => {
                        return(
                            <option value={i+config.car.number.length}>{arr[1]}</option>
                        )
                    })
                }
                <optgroup label="บุคคล"></optgroup>
                {
                    Object.entries(config.car.person).map((arr,i) => {
                        return(
                            <option value={i+config.car.number.length+config.car.company.length}>{arr[1]}</option>
                        )
                    })
                }
            </select>
            </>
        )
    }

    return(
        <>
            <div key={"usd1"+props.arr} className='job_name sub_head' style={{gridColumn: "1/3" ,whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} onClick={() => {
                set_popup(old => !old);
            }}>
                {
                    popup
                    ? <span>&and;</span>
                    : <span>&or;</span>
                }
            {props.headname}</div>
            <div style={{gridColumn: "3/4"}}>
                {type_display()}
            </div>
            <div style={{gridColumn: "4/6"}}>
                {update_all()}
            </div>
            
            {
                popup
                ? <>
                    <Edit deli_sta={props.deli_sta} head_len={props.head_len} all_chang={all_chang} search={props.search} head_id={props.headid} viewer={props.viewer} supTeam={(props.supTeam) ? props.supTeam : "0"} depart={props.depart} func_data={(data, to, date, help, deli_sta)=>add_data_head(data, to, date, help, deli_sta)}/>
                </>
                : <></>
            }
        </>
    )
}