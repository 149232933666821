import React, {useState, useEffect} from 'react'
import User_edit from './user_edit.js'
import Add_user from './add_user.js'
import './css/admin_table.css'
import config from '../../config.json'

export default function User_table(){
    const [table, set_table] = useState([])
    useEffect(() => {
        load_data()
    }, [])

    const load_data = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        const url = config.api_url+'?mode=depart&page=0&fin=0&userID=0&search=0&depart='+userToken
        console.log(url)
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data)
            set_table(data)}
        );
    }

    return(
        <div style={{padding: "1rem 2rem"}}>
            <Add_user />
            <table className='admin_table'>
                <thead>
                    <tr>
                        <th>username</th>
                        <th>แผนก</th>
                        <th style={{borderStyle: 'none'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.entries(table).map(arr => {

                            return(
                                <tr className='admin_table'>
                                    <td>{arr[1].user}</td>
                                    <td>{config.user_depart[arr[1].depart]}</td>
                                    <td style={{borderStyle: 'none'}}>
                                        <User_edit user={arr[1]} reload={load_data}/>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}