import React, {useState, useRef} from 'react'
import Add_sub from './add_sub';
import config from '../../../config.json'

const idString = sessionStorage.getItem('sup_team');
const userID = JSON.parse(idString);

export default function Add(props){
    const [Add_status, set_Add_status] = useState(false)
    const [type, set_type] = useState(0)
    const [job_amount, set_amount] = useState(1)
    const sub_head = useRef([...Array(20)])
    const sub_name = useRef([...Array(20)])
    const sub_deli_to = useRef([...Array(20).fill("", 0, 20)])
    const sub_deli_date = useRef([...Array(20).fill("", 0, 20)])
    const sub_amo = useRef([1,...Array(19).fill(0, 0, 19)])
    const [deli_status, set_deli_status] = useState(true)
    const [emg_status, set_emg_status] = useState(false)
    const [delivery_status, set_delivery_status] = useState(false)

    const name = useRef({})
    const care = useRef({})
    const deli_date = useRef({})
    const deli_to = useRef({})
    const note = useRef({})

    const input_text_except = "[^'\x22;]+"

    const Add = (e) => {
        e.preventDefault();
        if(name.current.value == ""){
            alert("โปรดใส่ Job name")
            return;
        }

        if(!deli_status){
            for(var index = 0; index < job_amount; index++){
                //console.log(sub_amo.current[index])
                for(var index2 = 0; index2 < sub_amo.current[index]; index2++){
                    if(sub_name.current[index][index2] == "" || sub_name.current[index][index2].includes('"') || sub_name.current[index][index2].includes("'") || sub_name.current[index][index2].includes(';')){
                        alert("โปรดกรอกชื่องานย่อยหัวข้อที่ "+(index+1)+" งานย่อยที่ "+(index2+1))
                        return
                    }
                    if(!delivery_status && (sub_deli_to.current[index][index2] == "" || sub_deli_to.current[index][index2].includes('"') || sub_deli_to.current[index][index2].includes("'") || sub_deli_to.current[index][index2].includes(';') ||
                    sub_deli_date.current[index][index2] == "")){
                        alert("โปรดกรอกรายละเอียดการส่งงานที่หัวข้อที่ "+(index+1)+" งานย่อยที่ "+(index2+1))
                        return;
                    }
                }
            }
        }

        if(name.current.value.includes('"') || name.current.value.includes("'") || name.current.value.includes(';')){
            alert("มีตัวอักษรที่ห้ามใช้งาน เช่น ; ' \"")
            return;
        }
        if(note.current.value.includes('"') || note.current.value.includes("'") || note.current.value.includes(';')){
            alert("มีตัวอักษรที่ห้ามใช้งาน เช่น ; ' \"")
            return;
        }
        if(note.current.value.length > 1000){
            alert("note มีความยาวได้ไม่เกิน 1,000 ตัวอักษร")
            return;
        }
        if(!delivery_status && deli_status && (deli_to.current.value.includes('"') || deli_to.current.value.includes("'") || deli_to.current.value.includes(';'))){
            alert("มีตัวอักษรที่ห้ามใช้งาน เช่น ; ' \"")
            return;
        }
        if(care.current.value.includes('"') || care.current.value.includes("'") || care.current.value.includes(';')){
            alert("มีตัวอักษรที่ห้ามใช้งาน เช่น ; ' \"")
            return;
        }
        if(!delivery_status && deli_status && (deli_to.current.value == "" || deli_date.current.value == "")){
            alert("โปรดกรอกรายละเอียดการส่งงาน")
            return;
        }

        //set_Add_status(false)
        var data_json = {
            mode: "add",
            name: name.current.value ? name.current.value : "-",
            type: type,
            note: note.current.value,
            deli_date: !delivery_status ? deli_status ? deli_date.current.value : "2000-1-1" : "2000-1-1",
            deli_to: !delivery_status ? deli_status ? deli_to.current.value : "0" : "0",
            deli_sta: delivery_status ? deli_status ? "1" : "2" : "0",
            care: care.current.value,
            sub_head: [...Array(job_amount)],
            deli_status: deli_status,
            emg: emg_status
        }

        for(var i = 0; i < job_amount; i ++){
            data_json.sub_head[i] = {
                name: sub_head.current[i],
                sub: sub_name.current[i],
                deli_to: !deli_status ? !delivery_status ? sub_deli_to.current[i] : [...Array(sub_deli_to.current.length).fill("",0,sub_deli_to.current.length)] : [],
                deli_date: !deli_status ? !delivery_status ? sub_deli_date.current[i] : [...Array(sub_deli_date.current.length).fill("2001-1-1",0,sub_deli_date.current.length)] : []
            }
        }
        data_json.sup_user = userID
        //console.log(data_json)
        //console.log(data_json)
        fetch(config.api_url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data_json)
        })
        .then((response) => response.json())
        .then((data) => {
            //console.log(data)
            window.location.reload()
        });
        enable()
    }

    const amount = () => {
        return(
            <div id="detail_form_sub_box">
                <div style={{display: "grid", gridTemplateColumns: "100px 1fr 1fr 1fr", gridColumnGap: "5px", gridRowGap: "5px"}}>
                    <label style={{textAlign: "left", gridColumn: "1/3"}}>
                        <input name="ck_deli" type="checkbox" onChange={e => set_deli_status(!e.target.checked)}/>
                        แยกวัน/เวลา/สถานที่ในการส่ง
                    </label>
                    <div style={{gridColumn: "3/5"}}></div>
                    <label style={{textAlign: "left", gridColumn: "1/3"}}>
                        <input name="ck_deli" type="checkbox" onChange={e => set_delivery_status(e.target.checked)}/>
                        ไม่กำหนดวัน/เวลา/สถานที่ในการส่ง
                    </label>
                    <div style={{gridColumn: "3/5"}}></div>
                    <label style={{textAlign: "left"}}>จำนวนสื่อ</label>
                    <input name="jobAmo" type="number" autoComplete='on' defaultValue={job_amount} style={{width: "40px", textAlign: "center", gridColumn: "2/5"}} onChange={val => set_amount(val.target.value)} min="1" max="20"/>
                    {
                        sub_name_add()
                    }
                </div>
            </div>
        )
    }

    const sub_name_add = () => {

        return(
            <>
                {
                    sub_head.current.map((val,i) => {
                        if(i >= job_amount)
                            return(<></>)
                        return(
                            <>
                                <label style={{height: "fit-content", textAlign: "left"}}>ชื่อสื่อ {i+1}</label>
                                <input autoComplete='on' name={"hname"+i} pattern={input_text_except} required style={{height: "fit-content"}} type="text" defaultValue={sub_head.current[i]} onChange={val => sub_head.current[i] = val.target.value}/>
               
                                <Add_sub arr={i} deli_status={deli_status} delivery_status={delivery_status} sub_name_func={
                                    (sub, to, date, amo) => {
                                        sub_name.current[i] = sub;
                                        sub_deli_to.current[i] = to
                                        sub_deli_date.current[i] = date
                                        sub_amo.current[i] = amo
                                    }
                                }/>
                            </>
                        )
                    })   
                }
            </>
        )
    }

    function disable(){
        document.body.classList.add('disable_scroll');
    }

    function enable(){
        document.body.classList.remove('disable_scroll');
    }
    function getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }
    function getHeight() {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        );
    }

    const type_select = () => {
        return(
            <select style={{width: "fit-content"}} onChange={e => set_type(e.target.value)}>
                {
                    Object.entries(config.type).map((val) => {
                        return(
                            <option value={val[0]}>
                                {val[1]}
                            </option>
                        )
                    })
                }
            </select>
        )
    }

    const emg = () => {
        return(
            <>
                <div></div>
                <label style={{color: "red"}}>
                    <input name="emg" type="checkbox" onChange={e => set_emg_status(e.target.checked)}/>
                    งานด่วน
                </label>
            </>
        )
    }

    return(
        <span style={{padding: "0", marginLeft: "16px"}}>
        <button id="add" onClick={() => {set_Add_status(!Add_status); note.current = ""; set_type(1); set_amount(1); sub_head.current = [...Array(20)]; disable();}}>&#10133;</button>
            {
            Add_status === true
            ?   <>
                <div className='Add_bg' style={{top: window.scrollY, width: getWidth(), height: getHeight()}} onClick={ () => {set_Add_status(false); enable();}}></div>
                <div className='Add' style={{backgroundColor: "#E9F6FE", width: "600px",top: (window.scrollY+(window.innerHeight*0.5)), left: (window.scrollX+(window.innerWidth*0.5)), translate: "-50% -50%"}}>
                    <div style={{color: "#000", padding: "24px", fontSize: "14px"}}>
                        <h1 style={{padding: "0", margin: "0", color: "#52C3F1"}}>{config.user_depart[props.depart]}</h1>
                        <form id="detail_form" autoComplete='on' noValidate style={{width: "100%"}} onSubmit={Add} autoC>
                            <label>Jobname : </label>
                            <input name='jobname' pattern={input_text_except} type="text" ref={name} required/>
                            <label>ผู้ดูแล : </label>
                            <input autoComplete='on' name="person" pattern={input_text_except} type="text" ref={care} required/>
                            <label>Type : </label>
                            {type_select()}
                            <label>ประเภทสื่อ : </label>
                            {amount()}
                            {
                                deli_status && !delivery_status
                                ? <div id="detail_form_sub">
                                    <label>ส่งของวันที่ : </label>
                                    <input name='deli_date' type="date" ref={deli_date} required/>
                                    <label>สถานที่ส่ง : </label>
                                    <input name='deli_to' type="text" ref={deli_to} pattern={input_text_except} required/>
                                </div>
                                : <></>
                            }
                            <label>Note : </label>
                            <textarea name="note" rows="4" ref={note}/>
                            {
                                emg()
                            }
                            <div></div>
                            <input name="submit" type="submit" value="OK"/>
                        </form>
                    </div>
                </div>
                </>
            : <></>}
        </span>
    )
}