import React, {useState, useEffect, useRef} from 'react'
import Update_status from '../../middleware/update_status'
import config from '../../../config.json'

export default function Sup_edit(props) {
    const detail = useRef({})
    const [data, set_data] = useState()
    const [render, set_render] = useState(0)
    const [text_search, set_text_search] = useState("")
    const [deli_sta, set_deli_sta] = useState(false)


    const name = useRef({})
    const care = useRef({})
    const deli_date = useRef({})
    const deli_to = useRef({})
    const note = useRef({})

    const load = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        const url = config.api_url+'?mode=view_sub&main_id='+props.main_id+'&depart='+userToken
        //console.log(url)
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            name.current.value = props.main_data.name
            care.current.value = props.main_data.care
            deli_date.current.value = props.main_data.deli_date
            deli_to.current.value = props.main_data.deli_to
            note.current.value = props.main_data.note
            //console.log(note.current.value)
            set_data(data);
            set_render(pre => pre+1)
        });
    }

    useEffect(() => {
        load()
    }, [props.main_data])

    const submit = (e) => {
        e.preventDefault();
        for(const key in detail.current){
            for(const key_sub in detail.current[key].detail){
                if(detail.current[key].detail[key_sub].to == 0 && detail.current[key].detail[key_sub].status == config.status_value.finish){
                    alert("โปรดเลือกแผนกที่จะส่งต่อ")
                    return;
                }
            }
            if(detail.current[key].all == 1){
                if(detail.current[key].status == 4 && detail.current[key].to == 0){
                    alert("โปรดเลือกแผนกที่จะส่งต่อ")
                    return;
                }
            }
        }

        const send = {
            mode: "0",
            main_id: props.main_id,
            name: name.current.value,
            care: care.current.value,
            deli_date: deli_date.current.value,
            deli_to: deli_to.current.value,
            note: note.current.value,
            detail: detail.current,
            deli_sta: deli_sta ? 1 : 0,
            depart: props.depart,
        }
        //console.log(send)
        fetch(config.api_url, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(send)
        })
        .then((response) => response.json(send))
        .then((data) => {
            //console.log(data)
            props.update()
        });
    }

    const del_main = (e, id) => {
        e.preventDefault()
        if(!window.confirm("คุณแน่ใจหรือไม่ที่จะลบข้อมูลนี้"))
            return;
        const send = {
            mode: "1",
            id: id
        }

        fetch(config.api_url, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(send)
        })
        .then((response) => response.json(send))
        .then((data) => {
            window.location.reload()
        });
    }

    const search = () => {
        return(
            <span style={{float: "right", padding: "0", marginBottom: "0"}}>
                <input style={{margin: "0 5px", height: "21px", border: "none", padding: "0", borderRadius: "3px"}} type="text" value={text_search} onChange={e => set_text_search(e.target.value)} placeholder='Search'/>
            </span>
        )
    }

    const display = () => {
        if(!data)
            return(<></>)

        return(
            <div style={{color: "#000", padding: "24px"}}>
                <h1 style={{padding: "0", margin: "0", color: "#52C3F1"}}>{config.user_depart[props.depart]}
                    {search()}
                </h1>
                <form id="detail_form" style={{width: "100%"}} onSubmit={submit}>
                    <label>Jobname : </label>
                    <input type="text" ref={name} defaultValue={name.current.value}/>
                    <label>ผู้ดูแล : </label>
                    <input type="text" ref={care} defaultValue={care.current.value}/>
                    <label>ประเภทสื่อ : </label>
                    <Update_status deli_sta={props.deli_sta} head_len={data.length} search={text_search} func_data={(data, arr)=> {detail.current[arr]=data;}} viewer={0} supTeam={props.supTeam} detail={data} depart={props.depart}/>
                    {
                        deli_to.current.value != 0 && props.deli_sta != 1
                        ? <div id="detail_form_sub">
                            <label>ส่งของวันที่ : </label>
                            <input type="date" ref={deli_date} defaultValue={deli_date.current.value}/>
                            <label>สถานที่ส่ง : </label>
                            <input type="text" ref={deli_to} defaultValue={deli_to.current.value}/>
                        </div>
                        : <></>
                    }
                    {
                        props.deli_sta == 1
                        ? <div id="detail_form_sub">
                            <label>ส่งของวันที่ : </label>
                            <input type="date" ref={deli_date} onChange={e => set_deli_sta(true)}/>
                            <label>สถานที่ส่ง : </label>
                            <input type="text" ref={deli_to} onChange={e => set_deli_sta(true)}/>
                        </div>
                        : <></>
                    }
                    <label>Note : </label>
                    <textarea rows="4" ref={note} defaultValue={note.current.value}/>
                    <button className='btn_del' onClick={(e) => del_main(e,props.main_id)}>ลบข้อมูลทั้งหมด</button>
                    <input type="submit" value="OK"/>
                </form>
            </div>
        )
    }

    return(
        <>
            {
                display()
            }
        </>
    )
}