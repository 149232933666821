import React, {useState, useEffect} from 'react'

export default function Popup(props){
    const [popup, set_popup] = useState(false)

    useEffect(() => {
        enable()
        set_popup(false)
    }, [props.turnOff])

    function getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }
    function getHeight() {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        );
    }
    function disable(){
        document.body.classList.add('disable_scroll');
    }

    function enable(){
        document.body.classList.remove('disable_scroll');
    }

    return(
        <>
            <button onClick={e => {set_popup(true); disable()}} style={{fontSize: props.fontSize ? props.fontSize : "16px"}}>
                {props.btn_text}
            </button>
            {
                popup
                ? <><div className='Add_bg' style={{border: "none",top: window.scrollY, width: getWidth(), height: getHeight()}} onClick={ () => {set_popup(false); enable();}}></div>
                    <div className='Add popup_box' style={{border: "none",backgroundColor: "#E9F6FE",top: (window.scrollY+(window.innerHeight*0.5)), left: (window.scrollX+(window.innerWidth*0.5)), width: props.width, height: props.height}}>
                        {
                            props.children
                        }
                </div></>
                : <></>
            }
        </>
    )
}