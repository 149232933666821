import React, {useState, useEffect, useRef} from 'react'
import config from '../../config.json'

export default function Company_select(props){
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    const [company_list, set_company_list] = useState([{
        id: 0,
        name: "none"
    }])
    const [search, set_search] = useState(props.send)
    const [search_id, set_search_id] = useState(0)
    const [click_search, set_click_search] = useState(false)
    const [click, set_click] = useState(false)
    const [change_detail, set_change_detail] = useState(0)

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => set_click(false));

    useEffect(() => {
        load()
        if(click_search){
            props.return({name: search, id: search_id})
            set_click_search(false)
        }
        if(props.load_data){
            set_search(props.send)
        }
        else{
            if(props.chg_det != change_detail){
                set_change_detail(props.chg_det)
                set_company_list([{
                    id: 0,
                    name: "none"
                }])
                set_search("")
                set_search_id(0)
            }
        }
    }, [search, click_search, props.load_data, props.chg_det])

    const load = () => {
        const url = config.api_url+"/doc/get.php?mode=0&search="+search+"&token="+userToken
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            set_company_list(data)
        });
    }
    return(
        <div style={{gridColumn: "1/3"}} ref={wrapperRef}>
            <input disabled={false} style={{width: "100%"}} onClick={e => {set_click(true); load()}}
                onChange={ e => {
                    set_search(e.target.value)
                    set_click_search(true)
                }}
                placeholder='ชื่อลูกค้า'
                value={search}
            />
            {
                click
                ? <div className='searchDropdown'>
                    {
                        Object.entries(company_list).map((val, i) => {
                            return(
                                <p onClick={() => {
                                    set_search(val[1].name);
                                    set_search_id(val[1].id);
                                    set_click_search(true)
                                }}>
                                    {val[1].name}
                                </p>
                            )
                        })
                    }
                </div>
                : <></>
            }
        </div>
    )
}

function useOutsideAlerter(ref, fuc) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        fuc()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}