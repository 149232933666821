import React, {useState, useRef, useEffect} from 'react'

export default function Add_sub(props){
    const [job_amount, set_amount] = useState(1)
    const [sub_name, set_sub_name] = useState([...Array(20).fill("",0 ,20)])
    const [deli_to, set_deli_to] = useState([...Array(20).fill("",0,20)])
    const [deli_date, set_deli_date] = useState([...Array(20).fill("",0,20)])
    const [count, set_count] = useState(0)

    const input_text_except = "[^'\x22;]+"

    useEffect(() => {
        props.sub_name_func(sub_name, deli_to, deli_date, job_amount)
    }, [count])

    const amount = () => {
        return(
                <>
                    <div style={{gridColumn: "3/5"}}>
                        <label style={{textAlign: "left"}}>จำนวนงาน</label>
                        <input name={"subAmo"+props.arr}  type="number" defaultValue={job_amount} style={{width: "40px", marginLeft: "5px"}} onChange={val => {set_amount(val.target.value); set_count(old => old+1)}} min="1" max="20"/>
                    </div>
                    {
                        sub_name_add()
                    }
                </>
        )
    }

    const sub_name_add = () => {

        return(
            <>
                {
                    sub_name.map((val,i) => {
                        if(i >= job_amount)
                            return(<></>)
                        return(
                            <>
                                <div></div>
                                <input autoComplete='on' name={"sname"+props.arr+"/"+i} pattern={input_text_except} style={{height: "fit-content"}} type="text" defaultValue={sub_name[i]} onChange={val => {
                                        var name = sub_name;
                                        name[i] = val.target.value;
                                        set_sub_name(name)
                                        set_count(old => old+1)
                                    }
                                } placeholder={'รายการที่ '+(i+1)} required/>
                                {
                                    deli_detail(i)
                                }
                            </>
                        )
                    })   
                }
            </>
        )
    }

    const deli_detail = (arr) => {
        const label_style = {textAlign: "left"}
        if(!props.deli_status)
        if(!props.delivery_status)
        return(
            <>
                <div></div>
                <div></div>
                <div></div>
                <div style={{gridColumn: "2/5", display: "grid", gridTemplateColumns: "100px 1fr", gridRowGap: "5px", gridColumnGap: "5px"}}>
                    <label style={label_style}>ส่งของวันที่</label>
                    <input name={"deli_date"+props.arr+"/"+arr} type="date" onChange={e => {
                        let old = deli_date
                        old[arr] = e.target.value
                        set_deli_date(old)
                        set_count(old => old+1)
                    }} disabled={props.delivery_status} required/>

                    <label style={label_style}>สถานที่ส่ง</label>
                    <input name={"deli_to"+props.arr+"/"+arr} type="text" onChange={e => {
                        let old = deli_to
                        old[arr] = e.target.value
                        set_deli_to(old)
                        set_count(old => old+1)
                    }} disabled={props.delivery_status} required/>
                </div>
            </>
        )
        else
            return(<>
                <div></div>
                <div></div>
            </>)
        else
            return(<>
                <div></div>
                <div></div>
            </>)
    }

    return(
        <>
            {
                amount()
            }
            <div style={{gridColumn: "1/5", borderBottom: "1px solid #263D7D"}}></div>
        </>
    )
}