import React, {useState, useRef} from 'react'
import config from '../../config.json'


export default function Add_user(){
    const [add_status, set_add_status] = useState(false)
    const [add_user, set_add_user] = useState('')
    const [add_pass, set_add_pass] = useState('')
    const [add_pass2, set_add_pass2] = useState('')
    const [add_depart, set_add_depart] = useState(0)
    const sup_team = useRef(0)

    const submit_user = (e) => {
         e.preventDefault();
        if(add_user === '' || add_pass === '' || add_depart === 0){
            alert("โปรดกรอกขอ้มูลให้ครบ")
            return;
        }
        if(add_pass !== add_pass2){
            alert("password ทั้ง 2 ไม่ตรงกัน")
            return;
        }
        var data_json = {
            mode: "add_user",
            user: add_user,
            pass: add_pass,
            depart: add_depart,
            sup_team: sup_team.current
        }
        fetch(config.api_url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data_json)
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.data === 1) alert("username ซ้ำ")
            else if(data.data === 0) {
                alert("สำเร็จ")
                set_add_status(false)
                window.location.reload()
            }
        });
        document.body.classList.remove('disable_scroll');
    }

    function getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }
    function getHeight() {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        );
    }

    return(
        <>
            <button id="add" onClick={() => {set_add_status(true); set_add_depart(0); document.body.classList.add('disable_scroll');}}>เพิ่ม user ใหม่</button>
            {
                add_status === false ? <></>
                :
                <>
                    <div className='Add_bg' onClick={ () => {set_add_status(false); document.body.classList.remove('disable_scroll');}} style={{top: window.scrollY, width: getWidth(), height: getHeight()}}></div>
                    <div className='Add' style={{top: (window.scrollY+(window.innerHeight*0.5)), left: (window.scrollX+(window.innerWidth*0.5)), translate: "-50% -50%"}}>
                        <form style={{width: '60%'}} onSubmit={submit_user}>
                            <h2>เพิ่ม user</h2><div></div>
                            <label>username</label>
                            <input type="text" onChange={(e) => set_add_user(e.target.value)}/>
                            <label>password</label>
                            <input type="password" onChange={(e) => set_add_pass(e.target.value)}/>
                            <label>ยืนยัน password</label>
                            <input type="password" onChange={(e) => set_add_pass2(e.target.value)}/>
                            <label>แผนก</label>
                            <select onChange={(e) => {set_add_depart(e.target.value); if(e.target.value == 2) sup_team.current = 1; else sup_team.current = 0}}>
                                {
                                    Object.entries(config.user_depart).map((arr, i) => {
                                        return(
                                            <option value={i}>{arr[1]}</option>
                                        )
                                    })
                                }
                            </select>
                            {
                                add_depart == 2
                                ? <>
                                    <label>support team</label>
                                    <select defaultValue={sup_team.current} onChange={val => sup_team.current = val.target.value}>
                                        <option value="1">Support team 1</option>
                                        <option value="2">Support team 2</option>
                                        <option value="3">Support team 3</option>
                                        <option value="5">Support team 5</option>
                                    </select>
                                </>
                                : <></>
                            }
                            <div></div>
                            <input id="submit" style={{width: '50%'}} type="submit"  value="เพิ่ม user"/>
                        </form>
                    </div>
                </>
            }
        </>
    )
}