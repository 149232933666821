import React, {useState, useEffect} from 'react'

export function Job_media(props){
    const [amount, set_amount] = useState(1)
    const [isLoad, set_isLoad] = useState(0)
    const [list, set_list] = useState([{
            id: 0,
            name: "",
            head: true,
            amo: 0,
            unit: ""
        }])

    useEffect(() => {
        if(props.old_data && isLoad != parseInt(props.load_id)){
            set_isLoad(parseInt(props.load_id))
            if(props.data != undefined){
                set_list(props.data);
                set_amount(props.data.length-1);
            }
            else{
                console.log("UNDEFINE")
                set_list([{
                    id: 0,
                    name: "",
                    head: true,
                    amo: 0,
                    unit: ""
                },{
                    id: 1,
                    name: "",
                    head: false,
                    amo: 1,
                    unit: ""
                }])
                set_amount(1);
            }
        }
        props.return(list);
    }, [list, props.old_data, props.load_id])

    const update_list = (data, index) => {
        var list_old = [...list];
        list_old[index] = data;
        set_list(list_old);
    }

    return(
        <>
          <input style={{gridColumn: "1/3"}} type={"text"} placeholder={props.number+". ประเภทสื่อ"} value={list[0].name} onChange={e => {
            var list_old = [...list];
            list_old[0].name = e.target.value;
            set_list(list_old)
          }}/>
          <input type={"number"} min={1} max={20} value={amount} onChange={e => {
            set_amount((e.target.value.length == 0 || parseInt(e.target.value) <= 0) ? 1 : parseInt(e.target.value))
            if(!(e.target.value.length == 0 || parseInt(e.target.value) <= 0))
                var listTemp = list.map(arr => ({...arr}))
                var lengtList = listTemp.length;
                if(listTemp.length < e.target.value){
                    for(var i = 0; i < e.target.value-lengtList; i++){
                        listTemp.push({
                            id: listTemp[listTemp.length-1].id+1,
                            name: "",
                            head: false,
                            amo: 1,
                            unit: ""
                        })
                    }
                }
                else if(listTemp.length > e.target.value){
                    for(var i = 0; i < lengtList-e.target.value-1; i++){
                        console.log(i+1)
                        listTemp.pop()
                    }
                }
                set_list(listTemp)
          }}/>
          <div></div>
          {
            [...Array(amount)].map((e, v) => {
                return(
                    <Job_media_sub number={v+1} return={val => update_list(val, v+1)} data={list[v+1]} isLoad={isLoad}/>
                )
            })
          }
        </>
    )
}

function Job_media_sub(props){
    const [amount, set_amount] = useState(1)
    const [name, set_name] = useState("")
    const [unit, set_unit] = useState("")
    const [isLoad, set_isLoad] = useState(0)

    useEffect(() => {
        if(isLoad != props.isLoad){
            if(props.data != undefined){
                set_name(props.data.name);
                set_unit(props.data.unit);
                set_amount(props.data.amo);
            }
            else{
                set_name("");
                set_unit("");
                set_amount(1);
            }
            set_isLoad(props.isLoad);
        }
        const out = {
            id: props.number,
            name: name,
            head: false,
            amo: amount,
            unit: unit
        }
        props.return(out);
    }, [name, amount, unit, props.isLoad])

    return(
        <>
            <div></div>
            <input type={"text"} value={name} placeholder={props.number+". รายการสื่อ"} onChange={e => set_name(e.target.value)}/>
            <input type={"number"} min={1} value={amount} onChange={e => {
                set_amount(parseInt(e.target.value))
            }}/>
            <input type={"text"} value={unit} placeholder='หน่วย' onChange={e => set_unit(e.target.value)}/>
        </>
    )
}