import React, {useState, useEffect, useRef} from 'react'
import Sup_edit from '../support/sup_edit'
import Viewer_sub_detail from './viewer_sub_detail'
import Monitor from '../../monitor/monitor'
import config from '../../../config.json'

const supString = sessionStorage.getItem('sup_team');
const usersupT = JSON.parse(supString);

export default function Viewer_sub(props){
    const [popup, set_popup] = useState(false)
    const [main_data, set_main_data] = useState()

    useEffect(() => {
        if(popup == true){
            load()
            props.stop_load(true)
        }
        else{
            props.stop_load(false)
        }
        //console.log(data_view)
    }, [popup])

    const load = () => {
        const url = config.api_url+'?mode=view_detail&main_id='+props.main_id
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            set_main_data(data)
        });
    }

    function getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }
    function getHeight() {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        );
    }
    function disable(){
        document.body.classList.add('disable_scroll');
    }

    function enable(){
        document.body.classList.remove('disable_scroll');
    }

    return(
        <>
            <div className='job_name' style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} onClick={() => {set_popup(true); disable(); }}>
                {props.name}
            </div>
            {
                popup
                ? <><div className='Add_bg' style={{top: window.scrollY, width: getWidth(), height: getHeight()}} 
                    onClick={ () => {
                        set_popup(false); enable();}
                    }
                    ></div>
                    <div className='Add popup_box' style={{backgroundColor: "#E9F6FE",top: (window.scrollY+(window.innerHeight*0.5)), left: (window.scrollX+(window.innerWidth*0.5)), width: "700px", height: "fit-content"}}>
                        {
                            /* form() */
                            props.depart == 2 && usersupT == props.supTeam
                            ? <Sup_edit deli_sta={props.deli_sta} supTeam={props.supTeam} main_id={props.main_id} main_data={main_data} depart={props.depart} click={popup} update={()=>{set_popup(false); enable(); props.load()}} />
                            : props.depart == 99 || props.depart == 100
                                ? <Monitor main_id={props.main_id} main_data={main_data} depart={props.depart} click={popup} update={()=>{set_popup(false); enable(); props.load()}}/>
                                : <Viewer_sub_detail main_id={props.main_id} main_data={main_data} depart={props.depart} click={popup} update={()=>{set_popup(false); enable(); props.load()}}/>
                        }
                </div></>
                : <></>
            }
        </>
    )
}