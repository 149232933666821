import React, {useEffect, useState} from 'react'
import Search from './middleware/search/search';
import { Link, useLocation } from 'react-router-dom';

import logo from '../img/logo.jpg'

import './css/nav.css'

function timeStr(){
    var date = new Date(Date.now())
    var h = date.getHours()
    var m = date.getMinutes()
    var s = date.getSeconds()
    return (Math.floor(h/10).toString()+(h%10).toString()+":"+Math.floor(m/10).toString()+(m%10).toString()+":"+Math.floor(s/10).toString()+(s%10).toString()+" น.")
}
function logOut(){
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('depart')
    window.location.reload()
}

export default function Nav(props) {
    const [ch_date, set_ch_date] = useState((JSON.parse(sessionStorage.getItem('date'))) ? JSON.parse(sessionStorage.getItem('date')) :new Date(Date.now()).getFullYear()+"-"+("0" + (new Date(Date.now()).getMonth()+1)).slice(-2) +"-"+("0" + (new Date(Date.now()).getDate())).slice(-2))
    const [date_now, set_date_now] = useState(timeStr())
    let location = useLocation();

    useEffect(() => {
        const interval = setInterval(() => {
            set_date_now(timeStr())
        }, 1000)
        sessionStorage.setItem('date', JSON.stringify(ch_date));
	    window.dispatchEvent(new Event("date"));
        sessionStorage.setItem('search', JSON.stringify(""));
        window.dispatchEvent(new Event("moni_search"));
    })

    const date_fun_in = (e) => {
        set_ch_date(e.target.value)
        sessionStorage.setItem('date', JSON.stringify(e.target.value));
        window.dispatchEvent(new Event("date"));
    }

    function getWidth() {
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            );
        }

    
    const width_nav = getWidth()

    return(
        <nav id="nav" style={{width: document.body.offsetWidth}}>
            <div className='nav_a'>
                <div>
                    <img src={logo} height={50}/>
                </div>
                <Link to="/">HOME</Link>
                <Link to="/monitor">MONITOR</Link>
                <Link to="/monitor_test">MONITOR (test)</Link>
                <Link to="/docsdeli">DOCS</Link>
                <Link to="/qrcode">QR Code</Link>
                {/* <Link to="/docsdeli_test">DOCS (test)</Link> */}
                {/* <Link to="/monitor_work">Monitor working</Link> */}
                <div></div>
                <button onClick={logOut} style={{fontWeight: "bold", fontSize: "14px"}}>LOG OUT</button>
            </div>
        </nav>
    )
}