import React, {useState, useEffect, useRef} from 'react'
import Update_status from '../middleware/update_status'
import config from '../../config.json'

export default function Sup_edit(props) {
    const detail = useRef({})
    const [data, set_data] = useState()
    const [text_search, set_text_search] = useState("")

    const name = useRef({})
    const care = useRef({})
    const deli_date = useRef({})
    const deli_to = useRef({})
    const note = useRef({})

    const load = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        const url = config.api_url+'?mode=view_sub&main_id='+props.main_id+'&depart='+props.depart
        //console.log(url)
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            name.current.value = props.main_data.name
            care.current.value = props.main_data.care
            deli_date.current.value = props.main_data.deli_date
            deli_to.current.value = props.main_data.deli_to
            note.current.value = props.main_data.note
            detail.current = data;

            set_data(data);
        });
    }

    useEffect(() => {
        load()
    }, [props.main_data])

    const del = (id) => {
        if(props.depart != 100 || props.depart == 100 && data.length == 1) return
        return(
            <button className='btn_del' onClick={(e) => delete_data(e ,id)}>ลบข้อมูล</button>
        )
    }

    const delete_data = (e, id) => {
        e.preventDefault()
        if(!window.confirm("คุณแน่ใจหรือไม่ที่จะลบข้อมูลนี้"))
            return;
        const send = {
            mode: "0",
            id: id
        }

        fetch(config.api_url, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(send)
        })
        .then((response) => response.json(send))
        .then((data) => {
            window.location.reload()
        });
    }

    const display_detail = () => {
        return(
            <>
                {
                    Object.entries(data).map(val => {

                        return(
                            <>
                                <Head_dropdonw headname={val[1].head_name} headid={val[1].head_id} search={text_search}/>
                                {/* <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", gridColumn: "1/3"}}>
                                    {val[1].head_name}
                                </div> */}
                            </>
                        )
                    })   
                }
            </>
        )
    }

    const del_main = (e, id) => {
        e.preventDefault()
        if(!window.confirm("คุณแน่ใจหรือไม่ที่จะลบข้อมูลนี้"))
            return;
        const send = {
            mode: "1",
            id: id
        }

        fetch(config.api_url, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(send)
        })
        .then((response) => response.json(send))
        .then((data) => {
            window.location.reload()
        });
    }

    const search = () => {
        return(
            <span style={{float: "right", padding: "0", marginBottom: "0"}}>
                <input style={{margin: "0 5px", height: "21px", border: "none", padding: "0", borderRadius: "3px"}} type="text" value={text_search} onChange={e => set_text_search(e.target.value)} placeholder='Search'/>
            </span>
        )
    }

    const display = () => {
        if(!data)
            return(<></>)
        return(
            <div style={{color: "#000", padding: "24px"}}>
                <h1 style={{padding: "0", margin: "0", color: "#52C3F1"}}>{config.user_depart[props.depart]}
                    {search()}
                </h1>
                <form id="detail_form" style={{width: "100%"}}>
                    <label>Jobname : </label>
                    <span>{name.current.value}</span>
                    <label>ผู้ดูแล : </label>
                    <span>{care.current.value}</span>
                    <label>ประเภทสื่อ : </label>
                    <div id="detail_form_sub_box" style={{overflowX: "scroll"}}>
                        <div className='data_sub' style={{gridTemplateColumns: "140px 100px 80px 400px 75px"}}>
                            <div style={{gridColumn: "1/3", border: "1px solid #52C3F1", borderStyle: "none none solid none", color: "#263D7D", fontWeight: "bold"}}>MEDIA</div>
                            <div style={{gridColumn: "3/5", border: "1px solid #52C3F1", borderStyle: "none none solid none", color: "#263D7D", fontWeight: "bold"}}>STATUS</div>
                            {display_detail()}
                        </div>
                    </div>
                    {/* <Update_status detail={data} depart={props.depart} return_value={(type, status, to, arr) => (update_status(type, status, to, arr))}/> */}
                    {
                        deli_to.current.value != 0
                        ? <div id="detail_form_sub">
                            <label>ส่งของวันที่ : </label>
                            <span>{deli_date.current.value}</span>
                            <label>สถานที่ส่ง : </label>
                            <span>{deli_to.current.value}</span>
                        </div>
                        : <></>
                    }
                    <label>Note : </label>
                    <span>{note.current.value}</span>
                    {
                        props.depart == 100
                        ? <button className='btn_del' onClick={(e) => del_main(e,props.main_id)}>ลบข้อมูลทั้งหมด</button>
                        : <div></div>
                    }
                    <input type="submit" value="OK"/>
                </form>
            </div>
        )
    }

    return(
        <>
            {
                display()
            }
        </>
    )
}

function Head_dropdonw(props){
    const [drop, set_drop] = useState(false)
    const [data, set_data] = useState()

    useEffect(() => {
        load()
    }, [props.search])

    const load = () => {
        const url = config.api_url+'?mode=view_moni&head_id='+props.headid+"&search="+props.search
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            set_data(data);
        });
    }

    const display_type = (type) => {

        return(
            <>
                <div className='type' style={{backgroundColor: config.type_color[type], color: "#fff"}}>
                    {
                        config.type[type]
                    }
                </div>
            </>
        )
    }

    const display_detail = (detail) => {
        if(detail)
        return(
            <>
                {
                    Object.entries(detail).map(val => {
                        return(
                            <p style={{padding: "0", margin: "0"}}>
                                &gt; {config.user_depart[val[1].depart]} : 
                                {config.status[val[1].status]}
                                &nbsp;
                            </p>
                        )
                    })
                }
            </>
        )
    }

    const deli_detail_display = (deli_to_f, deli_date_f) => {
        const label_style = {textAlign: "left"}
        if(deli_to_f != "0")
        return(
            <>
                <div style={{gridColumn: "1/3"}}></div>
                <div style={{gridColumn: "3/5", display: "grid", gridTemplateColumns: "100px 1fr", gridRowGap: "5px"}}>
                    <label style={label_style}>ส่งของวันที่</label>
                    <span>{deli_date_f}</span>
                    <label style={label_style}>สถานที่ส่ง</label>
                    <span>{deli_to_f}</span>
                </div>
            </>
        )
    }


    return(
        <>
            <div className='job_name sub_head' style={{gridColumn: "1/6" ,whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} onClick={() => {
                set_drop(old => !old);
                load();
            }}>
            {
                drop
                ? <>&and;</>
                : <>&or;</>
            }
            {
                props.headname
            }
            </div>
            {
                drop
                ? data
                    ? <>
                        {
                            data.length == 0
                            ? <div style={{gridColumn: "1/5", textAlign: "center"}}>
                                <div style={{width: "500px"}}>
                                    none
                                </div>
                            </div>
                            : <></>
                        }
                        {
                            Object.entries(data).map(val => {
                                return(
                                    <>
                                        <div key={"d1"+val[0]} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginLeft: "25px", gridColumn: "1/3"}}>
                                            {
                                                (parseInt(val[0])+1)
                                            }
                                            .
                                            {
                                                val[1].subname
                                            }
                                        </div>
                                        <div key={"d3"+val[0]}>
                                            {
                                                display_type(val[1].type)
                                            }
                                        </div>
                                        <div key={"d4"+val[0]}>
                                            {
                                                val[1].fin != 1
                                                ? display_detail(val[1].detail)
                                                : "เสร็จสิ้น"
                                            }
                                        </div>
                                        <div key={"d5"+val[0]}>

                                        </div>
                                        {
                                            deli_detail_display(val[1].deli_to, val[1].deli_date)
                                        }
                                    </>
                                )
                            })
                        }
                    </>
                    : <></>
                : <></>
            }
        </>
    )
}