import { json } from 'react-router-dom'
import config_pdf from '../config_docs.json'

const logoSize = 75
const font_default = 10
const border_none = [false, false, false, false]
const border_left = [true, false, false, false]

const line_thin_width = 0.1

const monthTH = ["มกราคม", "กุมภาพันธ์","มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]

const text_head = ["บริษัท ไซ แอ็ม มีเดีย เพรส โปรดัก จำกัด", "122/9 หมู่ที่ 11 ตำบลนราภิรมย์ อำเภอบางเลน จังหวัด นครปฐม 73130", "โทร. (662) 884-9464 e-mail : si-am@si-am.media", "สอบถามข้อมูลสินค้าได้ที่ Line ID : @si-ammedia"]
const docs_name = ["ใบส่งสินค้าชั่วคราว / Delivery Note", ["เลขที่", "วันที่", "อ้างอิง"]]

export const Header = (json_pdf, Date, border_color_default, logo1, logo2, bw_status) => {
    const out = [
        Header_title(logo1, logo2, bw_status),
        Name_doc(json_pdf, Date, border_color_default),
        customer_detail(json_pdf, border_color_default),
        media_name(json_pdf.headname),
        number_po_uhq(json_pdf),
    ]

    return out
}

const Header_title = (logo1, logo2, bw_status) => {
    
    return [
        {
            image: 'data:image/jpeg;base64,'+logo1,
            width: logoSize+20,
            absolutePosition: {
                x: 38,
                y: bw_status ? 20 : 8
            }
        },{
        layout: "noBorders",
            table: {
                widths: [ 100, '*', 100],
                body: [
                    [   {},
                        [
                            {text: text_head[0], alignment: "center", fontSize: font_default+3, bold: true},
                            {text: text_head[1], alignment: "center"},
                            {text: text_head[2], alignment: "center"},
                            {text: text_head[3], alignment: "center"}
                        ],
                        {}
                    ],
                ]
            }
        },
        {
            image: 'data:image/jpeg;base64,'+logo2,
            width: logoSize,
            absolutePosition: {
                x: 485,
                y: bw_status ? 18 : 17
            }
        },
    ]
}
        
const Name_doc = (json_pdf, Date, border_color_default) => {
    const out = {
                    table: {
                        widths: [ '*', 40, 110],
                        body: [
                            [
                                {text: docs_name[0], fontSize: font_default+9, alignment: "center", bold: true, border: border_none, rowSpan: 2, margin: [0, 10,0,0]},
                                {text: docs_name[1][0], border: border_left, borderColor: border_color_default},
                                {text: [
                                    {text: ":"},
                                    {text: json_pdf.number ? " "+json_pdf.number : "none"},
                                ], border: border_none}
                            ],

                            [
                                {text: "", border: border_none},
                                {text: docs_name[1][1], border: border_left, borderColor: border_color_default},
                                {text: [
                                    {text: ":"},
                                    {text: Date ? " "+Date[0] + " "+monthTH[(Date[1]-1)]+ " "+(parseInt(Date[2])+543) : "none"},
                                ], border: border_none}
                            ],

                            /* [
                                {text: "", border: border_none, alignment: "center",},
                                {text: docs_name[1][2], border: border_left, borderColor: border_color_default},
                                {text: [
                                    {text: ":"},
                                    {text: json_pdf.UHQ ? " "+json_pdf.UHQ : "none"},
                                ], border: border_none}
                            ], */
                            [
                                {text: json_pdf.KMMS ? "(กระทำการแทน KMMS)" : "", border: border_none, alignment: "center",},
                                {text: json_pdf.SMPP ? "SMPP" : "", border: border_left, borderColor: border_color_default, colSpan: 2, margin: [50, 0, 0, 0]}
                            ],
                        ]
                    },
                    margin: [0, 0, 0, 10]
                }   
    return out
}

const customer_detail = (json_pdf, border_color_default) => {
    const margin_detail = [0, -3, 0, 0]
    const line_height_detail = 0.7
    const width_contact = 90

    const out = {
        table: {
            widths: [ '*', 164],
            body: [
                [
                    {
                        text: [
                            {text: "สถานที่ส่ง ", fontSize: font_default+2, bold: true},
                            {text: json_pdf.company ? json_pdf.company : "-", fontSize: font_default+2, bold: true},
                            {text: json_pdf.company.length < 40 ? "\n\n" : "\n"},
                            {text: json_pdf.place ? json_pdf.place : "-", fontSize: font_default}
                        ],
                        border: [true, true, false, true],
                        borderColor: border_color_default
                    },
                    {
                        table: {
                            widths: [42, 1, '*'],
                            body: [
                                [
                                    {text: "ติดต่อ", bold: true, border: border_left, borderColor: border_color_default},
                                    {text: ":", border: border_none, borderColor: border_color_default},
                                    {
                                        table: {
                                            widths: [width_contact],
                                            body: [
                                                [
                                                    {text: json_pdf.name ? json_pdf.name : " ", border: [false, false, false, true], borderColor: border_color_default}
                                                ]
                                            ]
                                        },
                                        layout: 'underline',
                                        lineHeight: line_height_detail,
                                        margin: margin_detail,
                                        border: border_none
                                    },
                                ],
                                [
                                    {text: "เบอร์โทร", bold: true, border: border_left, borderColor: border_color_default},
                                    {text: ":", border: border_none, borderColor: border_color_default},
                                    {
                                        table: {
                                            widths: [width_contact],
                                            body: [
                                                [
                                                    {text: json_pdf.tellnum ? json_pdf.tellnum : " ", border: [false, false, false, true], borderColor: border_color_default}
                                                ]
                                            ]
                                        },
                                        layout: 'underline',
                                        lineHeight: line_height_detail ,
                                        margin: margin_detail,
                                        border: border_none
                                    },
                                ],
                                [
                                    {text: "กำหนดส่ง", bold: true, border: border_left, borderColor: border_color_default},
                                    {text: ":", border: border_none, borderColor: border_color_default},
                                    {
                                        table: {
                                            widths: [width_contact],
                                            body: [
                                                [
                                                    {text: json_pdf.deli_date ? parseInt(json_pdf.deli_date[0])+" "+monthTH[parseInt(json_pdf.deli_date[1])-1]+" "+(parseInt(json_pdf.deli_date[2])+543) : " ", border: [false, false, false, true], borderColor: border_color_default}
                                                ]
                                            ]
                                        },
                                        layout: 'underline',
                                        lineHeight: line_height_detail ,
                                        margin: margin_detail,
                                        border: border_none
                                    },
                                ]
                            ]
                        },
                        border: [false, true, true, true],
                        borderColor: border_color_default
                    }
                ]
            ]
        },
    }
    return out
}

const media_name = (headname) => {
    const out = {
        text: "สื่อ : "+headname,
        noWarp: false,
        fontSize: font_default+2,
        bold: true,
        margin: [0, 5, 0, 5]
    }
    return out
} 

const number_po_uhq = (json_detail) => {
    const font_addi = 4
    const out = {
        table: {
            widths: ['*', '*', '*'],
            body: [
                [
                    {text: "รหัสสินค้า "+json_detail.product_number, fontSize: font_default+font_addi},
                    {text: "เลขที่ PO: "+json_detail.po, fontSize: font_default+font_addi},
                    {text: "เลขที่ "+json_detail.UHQ, fontSize: font_default+font_addi}
                ]
            ],
        },
        layout: 'noBorders',
        margin: [0,0,0,0]
    }
    return out
}

export const table_detail = (json_detail, font_color, border_color_default, SMPP_KMMS) => {
    const line_max = (SMPP_KMMS) ? 24 : 25;
    const out = {
        table: {
            widths: [25, '*', 50, 100],
            headerRows: 2,
            body: table_detail_body(json_detail, font_color, border_color_default, SMPP_KMMS)
        },
        layout: {
            hLineWidth: function(i, node) {
                return (i < 3 || (i-27)%(line_max+1) == 0) ? 1 : 0.1;
            },
        },
        margin: [0, 0, 0, 0]
    }

    return out
}
const table_detail_body = (json_detail, font_color, border_color_default, SMPP_KMMS) => {
    const head_margin = [0, 7, 0, 0]
    var line_count = 1
    const line_max = (SMPP_KMMS) ? 22 : 23; // 23 24
    var break_status = false
    var count = 1
    var obj_line = []
    var line_break_count = (SMPP_KMMS) ? 23 : 24 // 24 25
    var head_no = 1;
    for(const head in json_detail){
        if(count%line_max == 0){
            obj_line.push({
                line: count++,
                name: 0,
                content: " ",
                amo: "",
                unit: 0,
                last: -1,
                break: 0
            })
            if(obj_line[head-1].last == 1){
                obj_line[head-1].last = 10
                obj_line.push({
                    line: count++,
                    name: 0,
                    content: " ",
                    amo: "",
                    unit: 0,
                    last: 1,
                    break: 0
                })
            }
        }
        obj_line.push({
            no: (json_detail[head].head) ? head_no++ : "",
            line: count++,
            head: json_detail[head].head,
            content: json_detail[head].name,
            amo: json_detail[head].amo,
            unit: json_detail[head].unit,
            break: (line_break_count == count-1) ? 1 : 0,
            last: ((json_detail[parseInt(head)+2] && json_detail[parseInt(head)+2].head == true) || (json_detail.length-(parseInt(head)+1) == 1)) ? 1 : ((json_detail[parseInt(head)+1] && json_detail[parseInt(head)+1].head == true) || (json_detail.length-(parseInt(head)+1) == 0)) ? 0 : 10
        })
        /* for(const sub in json_detail[head].sub){
            if(json_detail[head].sub.length-(parseInt(sub)+1) == 1 && count%line_max == 0)
                obj_line.push({
                    line: count++,
                    name: 0,
                    content: " ",
                    amo: "",
                    unit: 0,
                    last: -1,
                    break: line_break_count == count-1 ? 1 : 0
                })
            obj_line.push({
                line: count++,
                name: 0,
                content: json_detail[head].sub[sub].name,
                amo: json_detail[head].sub[sub].amo,
                unit: json_detail[head].sub[sub].unit,
                last: json_detail[head].sub.length-(parseInt(sub)+1),
                break: line_break_count == count-1 ? 1 : 0
            })
            line_break_count = line_break_count == count-1 ? line_break_count+25 : line_break_count
        } */
        line_break_count = line_break_count == count-1 ? line_break_count+(25) : line_break_count
    }

    const obj_push = (no, list, amo, unit, last, bfat, btm_bor) => {
        const border_body = [true, false, true, btm_bor]
        const numberFormatter = Intl.NumberFormat('en-US');
        var fontAmoSize = font_default
        if(parseInt(amo) >= 1000000)
            fontAmoSize = font_default-3
        else if(parseInt(amo) >= 10000)
            fontAmoSize = font_default-2

        var column4 = []
        if(last == 1)
            column4 = {
                text: [
                    {text: "a\t", color: "#fff"},
                    {text: " ☐", font: "emoji"},
                    {text: "\t\t"},
                    {text: "_______\n", decoration: "underline", color: "#fff", decorationColor: font_color,},

                    {text: "a\t", color: "#fff"},
                    {text: "ครบ"},
                    {text: "\t\tผู้ตรวจ"}
                ],
                margin: [5, 0, 5, 0],
                border: [true, false, true, true], borderColor: border_color_default, rowSpan: 2,
            }
        else
            column4 = {text: " ",border: border_body, borderColor: border_color_default}
        const out = [
            {text: no, border: border_body, borderColor: border_color_default, alignment: "center", bold: (no != "" ? true : false), pageBreak: bfat},
            {text: list == "" ? "-" : list, border: border_body, borderColor: border_color_default, bold: (no != "" ? true : false)},
            {text: {
                text: (amo != 0 && !isNaN(amo) ? numberFormatter.format(amo) +" " : " ") + (unit != 0 ? unit : ""), fontSize: fontAmoSize
            }, border: border_body, borderColor: border_color_default, alignment: "right"},
            column4
        ]
        return out
    }

    var out = [
        [
            {text: "ลำดับ", bold: true, borderColor: border_color_default, alignment: "center", rowSpan: 2, margin: head_margin},
            {text: "รายการ", bold: true, borderColor: border_color_default, alignment: "center", rowSpan: 2, margin: head_margin},
            {text: "จำนวน", bold: true, borderColor: border_color_default, alignment: "center", rowSpan: 2, margin: head_margin},
            {text: [
                {text: "ตรวจสอบสินค้า\n"},
                {text: "(สำหรับพนักงานบริษัทฯ และขนส่ง)", fontSize: font_default-3},
            ], bold: true, borderColor: border_color_default, alignment: "center", rowSpan: 2}
        ],
        [
            {},{},{},{}
        ]
    ]
    for(const val in obj_line){
        if(obj_line[val].head){
            out.push(obj_push(parseInt(obj_line[val].no), obj_line[val].content, 0, 0, obj_line[val].last, obj_line[val].break == 1 ? "before" : "", line_count%line_max == 0 || obj_line[val].last == 0))
        }
        else{
            out.push(obj_push("", obj_line[val].content, obj_line[val].amo, obj_line[val].unit, obj_line[val].last, obj_line[val].break == 1 ? "before" : "", line_count%line_max == 0 || obj_line[val].last == 0))
        }
        line_count++
    }

    if(line_count%(line_max+1) != 0 && (line_count-1)%line_max != 0){
        const line_count_start = line_count
        const line_last = (line_count_start%line_max != 0) ? ((1+(Math.floor((line_count_start)/line_max)))*line_max) : line_max
        for(; line_count <= line_last; line_count++){
            out.push(obj_push("", " ", 0, 0, 10, "", ((line_count)%(line_max) == 0)))
        }
    }
    return out
}

export const footer = (font_color) => {
    const blank_for = (index) => {
        var blank_out = ""
        for(var i = 0; i < index; i++)
            blank_out += "_"
        return blank_out;
    }
    const table_width_val = "*"
    const table_width = [table_width_val, table_width_val, table_width_val, table_width_val, table_width_val]
    const underline_val = 13
    const underline_date_val = 4

    const out1 = {
        text: "*หากได้รับสินค้าแล้ว กรุณาเซ็นต์ชื่อ พร้อมประทับตรา และส่งกลับมาที่ e-mail : si-am@si-am.media",
        alignment: "center",
        margin: [0,0,0,20]
    }
    const out2 = {
        table: {
            widths: table_width,
            body: [
                [
                    {
                        text: [
                            {text: "\n\n"},
                            {text: "     ไพรวัล ศรีแป้     ", decoration: "underline", color: "#000", decorationColor: font_color},
                            {text: "\nผู้ส่งสินค้า"}
                        ],
                        border: border_none
                    },
                    {
                        text: [
                            {text: "\n\n"},
                            {text: blank_for(underline_date_val), decoration: "underline", color: "#fff", decorationColor: font_color},
                            {text: " /"},
                            {text: blank_for(underline_date_val), decoration: "underline", color: "#fff", decorationColor: font_color},
                            {text: " /"},
                            {text: blank_for(underline_date_val), decoration: "underline", color: "#fff", decorationColor: font_color},
                            {text: "\nวันที่"}
                        ],
                        border: border_none
                    },
                    {
                        text: "\n\nประทับตรา",
                        border: border_none
                    },
                    {
                        text: [
                            {text: "\n\n"},
                            {text: blank_for(underline_val), decoration: "underline", color: "#fff", decorationColor: font_color},
                            {text: "\nผู้รับสินค้า\n"},
                            {text: "(ลงชื่อพร้อมประทับตรา)", fontSize: font_default-2},
                        ],
                        border: border_none
                    },
                    {
                        text: [
                            {text: "\n\n"},
                            {text: blank_for(underline_date_val), decoration: "underline", color: "#fff", decorationColor: font_color},
                            {text: " /"},
                            {text: blank_for(underline_date_val), decoration: "underline", color: "#fff", decorationColor: font_color},
                            {text: " /"},
                            {text: blank_for(underline_date_val), decoration: "underline", color: "#fff", decorationColor: font_color},
                            {text: "\nวันที่"}
                        ],
                        border: border_none

                    }
                ],

                [
                    {text: "",border: border_none},{text: "",border: border_none},{text: "",border: border_none},
                    {
                        colSpan: 2,
                        text: "FM-LG-02 Rev01 บังคับใช้ 09/09/2023"/* "FM-LG-02 Rev00 บังคับใช้ 01/07/2021" */, fontSize: font_default-3, border: border_none, alignment: "right"
                    },
                ]
            ]
        },
        alignment: "center",
    }

    return [out1, out2]
}