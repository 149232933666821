import React, {useState, useEffect, useRef} from 'react'
import Update_status from '../../middleware/update_status'
import config from '../../../config.json'

export default function Sup_edit(props) {
    const detail = useRef({none: 1})
    const [data, set_data] = useState()
    const [text_search, set_text_search] = useState("")
    const [frist, set_frist] = useState(false)

    const name = useRef({})
    const care = useRef({})
    const deli_date = useRef({})
    const deli_to = useRef({})
    const note = useRef({})

    const load = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        const url = config.api_url+'?mode=view_sub&main_id='+props.main_id+'&depart='+userToken
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            if(!frist){
                name.current.value = props.main_data.name
                care.current.value = props.main_data.care
                deli_date.current.value = props.main_data.deli_date
                deli_to.current.value = props.main_data.deli_to
                note.current.value = props.main_data.note
                detail.current = data;
                set_frist(true)
            }
            set_data(data);
        });
    }

    useEffect(() => {
        load()
    }, [props.main_data])

    const submit = (e) => {
        e.preventDefault();

        for(const key in detail.current){
            for(const key_sub in detail.current[key].detail){
                if(detail.current[key].detail[key_sub].to == 0 && detail.current[key].detail[key_sub].status == config.status_value.finish && props.depart != 22){
                    alert("โปรดเลือกแผนกที่จะส่งต่อ")
                    return;
                }
                if(props.depart == 19 && detail.current[key].detail[key_sub].status == config.status_value.work && !detail.current[key].detail[key_sub].car){
                    alert("โปรดเลือกรถในการจัดส่ง")
                    return
                }
            }
            if(detail.current[key].all == 1){
                if(detail.current[key].status == 4 && detail.current[key].to == 0){
                    alert("โปรดเลือกแผนกที่จะส่งต่อ")
                    return;
                }
            }
        }

        const send = {
            mode: "0",
            main_id: props.main_id,
            name: name.current.value,
            care: care.current.value,
            deli_date: deli_date.current.value,
            deli_to: deli_to.current.value,
            note: note.current.value,
            detail: detail.current ,
            depart: props.depart,
        }
        //console.log(send)
        fetch(config.api_url, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(send)
        })
        .then((response) => response.json(send))
        .then((data) => {
            props.update()
        });
    }

    const search = () => {
        return(
            <span style={{float: "right", padding: "0", marginBottom: "0"}}>
                <input style={{margin: "0 5px", height: "21px", border: "none", padding: "0", borderRadius: "3px"}} type="text" value={text_search} onChange={e => set_text_search(e.target.value)} placeholder='Search'/>
            </span>
        )
    }

    const display = () => {
        if(!data)
            return(<></>)

        return(
            <div style={{color: "#000", padding: "24px"}}>
                <h1 style={{padding: "0", margin: "0", color: "#52C3F1"}}>{config.user_depart[props.depart]}
                    {search()}
                </h1>
                <form id="detail_form" style={{width: "100%"}} onSubmit={submit}>
                    <label>Jobname : </label>
                    <span>{name.current.value}</span>
                    <label>ผู้ดูแล : </label>
                    <span>{care.current.value}</span>
                    <label>ประเภทสื่อ : </label>
                    <Update_status search={text_search} func_data={(data2, arr)=> {detail.current[arr]=data2; load()}} supTeam={0} viewer={1} detail={data} depart={props.depart}/>
                    {
                        deli_to.current.value != 0
                        ? <div id="detail_form_sub">
                            <label>ส่งของวันที่ : </label>
                            <span>{deli_date.current.value}</span>
                            <label>สถานที่ส่ง : </label>
                            <span>{deli_to.current.value}</span>
                        </div>
                        : <></>
                    }
                    <label>Note : </label>
                    <textarea rows="4" ref={note}/>
                    <div></div>
                    <input type="submit" value="OK"/>
                </form>
            </div>
        )
    }

    return(
        <>
            {
                display()
            }
        </>
    )
}