import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts"

import {logo1, logo2, logo1_bw, logo2_bw} from "./img"

import { PDFDocument, rgb, degrees, StandardFonts  } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit';

import { Anuphan_Regular, Anuphan_semiBold, OpensansEmoni } from "./font";

import { Header, table_detail, footer } from "./pdf_component";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["Anuphan_Regular.ttf"] = Anuphan_Regular
window.pdfMake.vfs["Anuphan_semiBold.ttf"] = Anuphan_semiBold
window.pdfMake.vfs["emo.ttf"] = OpensansEmoni

pdfMake.fonts = {
    Anuphan: {
        normal: "Anuphan_Regular.ttf",
        bold: 'Anuphan_semiBold.ttf'
    },
    emoji: {
        normal: "emo.ttf"
    },

    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    }
}

pdfMake.tableLayouts = {
    underline: {
        hLineWidth: function (i, node) {
           return 0.1
        }
    }
};

const font_default = 10
const font_color = "#6196D1"
const border_color_default = [font_color, font_color, font_color, font_color]
const margin_LR = 40


export default async function PrintPDF(json_pdf, Date, job_pdf_detail) {
    var docDefinition = {
        info: {
            title: json_pdf.number
        },
        header: 
        {
            columns: [
                Header(json_pdf, Date, border_color_default, logo1, logo2, false)
            ],
            margin: [margin_LR,20,margin_LR,40]
        },
        footer: [
            {
                columns: [
                    footer(font_color),
                ],
                margin: [margin_LR, 0, margin_LR, 0]
            },
            {
                canvas: [
                    {
                        type: 'ellipse',
                        x: 297, y: 68,
                        color: 'red',
                        fillOpacity: 0,
                        lineColor: font_color,
                        r1: 40, r2: 40,
                        lineWidth: 0.1
                        
                    },
                ],
                absolutePosition: [150,140]
        }
        ],
        content: [
            table_detail(job_pdf_detail, font_color, border_color_default),
        ],
        styles: {

        },
        defaultStyle: {
            font: 'Anuphan',
            color: font_color,
            fontSize: font_default,
        },
        pageSize: 'A4',
        pageMargins: [margin_LR, 250, margin_LR, 120],
    }
    var pdfDocGenerator = pdfMake.createPdf(docDefinition)
    const raw_base64 = await new Promise(resolve => {
        pdfDocGenerator.getBase64(resolve);
    });

    const raw_base64_dw = await DownloadPDF(json_pdf, Date, job_pdf_detail)
    var base64 = ["", ""]
    base64[0] = await pdf_edit(raw_base64, true)
    base64[1] = await pdf_edit(raw_base64_dw, false)
    return base64
}


async function pdf_edit(base64, fontColor){
    const pdfDoc = await PDFDocument.load(base64)
    pdfDoc.registerFontkit(fontkit);
    const font1 = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const font2 = await pdfDoc.embedFont(Anuphan_Regular)

    const pages = pdfDoc.getPages()
    for(var key in pages){
        const edit = pages[key];
        const { width, height } = edit.getSize()
        edit.drawText(`${(parseInt(key)+1)}/${pdfDoc.getPageCount()}`, {
            x: width-55,
            y: 730,
            size: font_default,
            font: font2,
            color: fontColor ? rgb(0.38, 0.59, 0.82) : rgb(0,0,0),
        })

    }
    return await pdfDoc.saveAsBase64({ dataUri: true })
}

async function DownloadPDF(json_pdf, Date, job_pdf_detail) {
    var docDefinition = {
        info: {
            title: json_pdf.number
        },
        header: 
        {
            columns: [
                Header(json_pdf, Date, "#000", logo1_bw, logo2_bw, true)
            ],
            margin: [margin_LR,20,margin_LR,40]
        },
        footer: [
            {
                columns: [
                    footer("#000"),
                ],
                margin: [margin_LR, 0, margin_LR, 0]
            },
            {
                canvas: [
                    {
                        type: 'ellipse',
                        x: 297, y: 68,
                        color: 'red',
                        fillOpacity: 0,
                        lineColor: "#000",
                        r1: 40, r2: 40,
                        lineWidth: 0.1
                        
                    },
                ],
                absolutePosition: [297, 68]
        }
        ],
        content: [
            table_detail(job_pdf_detail, "#000", "#000"),
        ],
        styles: {

        },
        defaultStyle: {
            font: 'Anuphan',
            color: "#000",
            fontSize: font_default,
        },
        pageSize: 'A4',
        pageMargins: [margin_LR, 250, margin_LR, 120],
    }
    var pdfDocGenerator = pdfMake.createPdf(docDefinition)
    const raw_base64 = await new Promise(resolve => {
        pdfDocGenerator.getBase64(resolve);
    });

    return raw_base64
}

/* 
header: 
        {
            columns: [
                Header(json_pdf, Date, 0, 0)
            ],
            margin: [40,20,40,40]
        },
 */

/* 
        header: (cur, count) => {
            return {
                columns: [
                    Header(json_pdf, Date, cur, count)
                ],
                margin: [40,20,40,40]
            }
        },
         */