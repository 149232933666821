import React, {useEffect} from 'react'

export default function AutoScroll(){
    var autoScrollwTimeout, autoScrollInterval;
    var timeout1, timeout2;
    var checkAuto = false

    useEffect(() => {
        document.addEventListener("mousemove", AutoScrolling);
        return( () =>{
            document.removeEventListener("mousemove", AutoScrolling);
            clearTimeout(autoScrollwTimeout)
            clearTimeout(timeout1)
            clearTimeout(timeout2)
            clearInterval(autoScrollInterval)
        })
    }, [])


    function AutoScrolling(){
        clearTimeout(autoScrollwTimeout)
        clearTimeout(timeout1)
        clearTimeout(timeout2)
        clearInterval(autoScrollInterval)
        if(!document.body.classList.value.includes("disable_scroll"))
            autoScrollwTimeout = setTimeout(() => {
                autoScrollInterval = setInterval(() => {
                    if(window.scrollY >= window.scrollMaxY && !checkAuto){
                        checkAuto = true
                        timeout1 = setTimeout(() => {
                            window.scrollTo(0,0)
                            timeout2 = setTimeout(() => {
                                checkAuto = false
                            }, 5000)
                        }, 5000)
                    }
                    if(!checkAuto){
                        window.scrollBy(0, 5)

                    }
                }, 250)
            }, 10000)
            /* timeStampMouseMove = (new Date().getTime())+10000
            console.log(timeStampMouseMove) */
    }
    /* return(
        <div>
            <input type="checkbox" />
            <label style={{fontSize: "12pt"}}>
                Auto scroll
            </label>
        </div>
    ) */
}