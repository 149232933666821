import Reac, {useState, useEffect} from 'react'
import { Address_edit } from './docs_deli'
import config from '../../config.json'

export default function Edit_company(props){
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    const input_text_except = "[^'\x22;]+"
    const [search, set_search] = useState("")
    const [update, set_update] = useState(0)
    const [loaded, set_loaded] = useState(false)
    const [company, set_company] = useState({})

    const [data, set_data] = useState({})

    useEffect(() => {
        load()
    }, [update])

    const load = () => {
        const url = config.api_url+"/doc/get.php?mode=5&search="+search+"&token="+userToken
        //console.log(url);
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            set_data(data)
        });
    }
    const load_detail = (id) => {
        const url = config.api_url+"/doc/get.php?mode=6&id="+id+"&token="+userToken
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            set_company(data[0])
            set_loaded(true)
        });
    }

    const update_obj_state = (val) => {
        set_company(old => ({
            ...old,
            ...val
        }))
    }

    const del_customer = (e) => {
        e.preventDefault();
        const data_json = {
            mode: 0,
            token: userToken,
            id: company.id
        }
        const url = config.api_url+"/doc/delete.php"
        fetch(url, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data_json)
        })
        .then((response) => response.json())
        .then((data) => {
            alert("สำเร็จ")
            window.location.reload()
        });
    }

    const update_company = (e) => {
         e.preventDefault();
        const data_json = {
            mode: 1,
            token: userToken,
            obj: {
                id: company.id,
                name: company.customer_name,
                addre: company.customer_addre
            }
        }

        const url = config.api_url+"/doc/put.php"
        fetch(url, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data_json)
        })
        .then((response) => response.json())
        .then((data) => {
            alert("สำเร็จ")
            window.location.reload()
        });
    }

    if(!loaded)
        return(
            <div className='Load_popup' style={{border: "none", padding: "10px"}}>
                <input type="text" style={{width: "450px", display: "flex", margin: "auto"}} placeholder='ค้นหา' value={search}
                    onChange={ e => {
                        set_search(e.target.value);
                        set_update(old => old+1)
                    }}
                />
                <div style={{overflowY: "scroll", height: "400px"}}>
                    {
                        data == {}
                        ? <p>none</p>
                        : <>
                            <div className='Load_company_detail_head' style={{gridTemplateColumns: "1fr"}}>
                                <p>ชื่อลูกค้า</p>
                            </div>
                            {
                                Object.entries(data).map((val, i) => {
                                    return(
                                        <div className='Load_company_detail' style={{gridTemplateColumns: "1fr"}}
                                            onClick={e => {
                                                load_detail(val[1].id)
                                            }}
                                        >
                                            <p>{val[1].customer_name}</p>
                                        </div>
                                    )
                                })
                            }
                        </>
                    }
                </div>
            </div>
        )
    
    return(
        <div className='Load_popup' style={{border: "none", padding: "10px"}}>
            <form style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridGap: "5px"}} onSubmit={update_company}>
                <label>ชื่อลูกค้า</label>
                <input required style={{gridColumn: "2/5"}} type="text" pattern={input_text_except} value={company.customer_name}
                    onChange={e => {
                        update_obj_state({customer_name: e.target.value})
                    }}
                />
                <label>ที่อยู่</label>
                <textarea rows={4} defaultValue={company.customer_addre} style={{resize: "none", gridColumn: "2/5"}}
                    onChange={ e => {
                        update_obj_state({customer_addre: e.target.value})
                    }}
                />
                {/* <Address_edit add_ck={true} text_address={val => {set_place(val); set_update(old => old+1)}}/> */}
                    
                <button className='btn_submit' style={{backgroundColor: "red", color: "#000", fontSize: "15px"}} onClick={del_customer}>
                    ลบ
                </button>
                <div style={{gridColumn: "2/4"}}></div>
                <input type="submit" value="บันทึก" className='btn_submit'/>
            </form>
        </div>
    )
    
}