import Reac, {useState} from 'react'
import { Address_edit } from './docs_deli'
import config from '../../config.json'

export default function New_company(props){
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    const [place, set_place] = useState("")
    const [company, set_company] = useState("")
    const [update, set_update] = useState(0)
    const input_text_except = "[^'\x22;]+"

    const submit = (e) => {
        e.preventDefault();
        const data_json = {
            mode: "1",
            name: company,
            code: "",
            place: place,
            token: userToken
        }

        fetch(config.api_url+"/doc/post.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data_json)
        })
        .then((response) => response.json())
        .then((data) => {
            //console.log(data)
            props.sended()
        });
    }


    return(
        <div className='Load_popup' style={{border: "none", padding: "10px"}}>
            <form style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridGap: "5px"}} onSubmit={submit}>
                <label>ชื่อลูกค้า</label>
                <input required style={{gridColumn: "2/5"}} type="text" pattern={input_text_except}
                    onChange={e => {
                        set_company(e.target.value)
                    }}
                />
                <Address_edit add_ck={true} text_address={val => {set_place(val); set_update(old => old+1)}}/>
                    
                <div style={{gridColumn: "1/4"}}></div>
                <input type="submit" value="บันทึก" className='btn_submit'/>
            </form>
        </div>
    )
}