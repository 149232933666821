import React, {useState} from 'react'
import './css/login.css'
import config from '../config.json'

export default function Login({user_data}){
    const [user, set_user] = useState()
    const [pass, set_pass] = useState()

    let post_login = (e) => {
        e.preventDefault();
        var data_json = {
            mode: "login",
            user: user,
            pass: pass
        }
        fetch(config.api_url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data_json)
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.token !== "invaild" && data.depart !== 99)
                user_data(data);
            else{
                alert("username หรือ password ไม่ถูกต้อง")
            }
            window.location.reload()
        });
    }

    return(
        <>
            <div className='login'>
                <div className='login_text'>
                <   h1>Login</h1>
                </div>
                <form onSubmit={post_login}>
                    <label>username</label>
                    <input type="text" onChange={(e) => set_user(e.target.value)}/>
                    <label>password</label>
                    <input type="password" onChange={(e) => set_pass(e.target.value)}/>
                    <input className='login_submit' type="submit" value="login"/>
                </form>
            </div>
        </>
    )
}