import Nav from './component/nav.js'
import Index from './component/index/index.js'
import Login from './component/login.js'
import Viewer from './component/index/viewer/viewer_page.js'
import Viewer_2 from './component/index/viewer_2/viewer_page.js'
import Docs_deli from './component/docs_pdf/docs_deli.js'
import Docs_deli2 from './component/docs_pdf/docs_deli_2.js'
import Docs_deli3 from './component/docs_pdf/docs_deli_3.js'
import config from './config.json'
import './App.css';
import './component/css/table.css'
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import React, {useEffect, useState} from 'react'
import Qrcode from './component/index/qrcode/qrcode.js'
/* "api_url" : "http://35.198.236.99/php/", */

function setToken(data){
  sessionStorage.setItem('token', JSON.stringify(data.token));
  sessionStorage.setItem('depart', JSON.stringify(data.depart));
  sessionStorage.setItem('sup_team', JSON.stringify(data.sup_team));
  sessionStorage.setItem('username', JSON.stringify(data.username));
}

function getToken(){
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  const departString = sessionStorage.getItem('depart');
  const userDepart = JSON.parse(departString);
  const supString = sessionStorage.getItem('sup_team');
  const usersupT = JSON.parse(supString);
  const nameString = sessionStorage.getItem('username');
  const username = JSON.parse(nameString);
  var data = {
    "token" : userToken,
    "depart" : userDepart,
    "sup_team": usersupT,
    "username": username
  }
  return data
}

function App() {
  var user = getToken()
  const [search, set_search] = useState("")

  useEffect(() => {
    document.title = "Job status"+ (user.depart ? " | "+config.user_depart[user.depart] : "");
  }, [])

  if(!user.token) return <Login user_data={setToken}/>
  return (
    <Router>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@500&display=swap');
      </style>
      <Nav search_func={val => {set_search(val);}}/>
        <Routes>
          <Route exact path="/" element={<Index depart={user.depart} />}/>
          <Route exact path="/monitor" element={<Viewer depart={99}/>}/>
          <Route exact path="/monitor_test" element={<Viewer_2 depart={99}/>}/>
          <Route exact path="/docsdeli" element={<Docs_deli3 />}/>
          <Route exact path="/qrcode" element={<Qrcode />}/>
          {/* <Route exact path="/monitor" element={<Data_table depart={user.depart} search_in={search}/>}/> */}
          {/* <Route exact path="/monitor_work" element={<Working depart={user.depart} search_in={search}/>}/> */}
        </Routes>
    </Router>
  );
}

export default App;
