import React from 'react'
import qrcode1 from '../../../qrcode/คู่มือพนักงาน_ฉบับสำเนา_ประกาศใช้.png'
import qrcode2 from '../../../qrcode/สวัสดิการ_ฉบับประกาศใช้.png'
import qrcode3 from '../../../qrcode/แก้ไขเพิ่มเติม_กฏข้อบังคับ_แผนกขนส่ง.png'

export default function Qrcode(){
    const qrCode = [
        {
            label: "คู่มือพนักงาน ฉบับสำเนา ประกาศใช้",
            path: qrcode1
        },
        {
            label: "สวัสดิการ ฉบับประกาศใช้",
            path: qrcode2
        },
        {
            label: "แก้ไขเพิ่มเติม กฏข้อบังคับ แผนกขนส่ง",
            path: qrcode3
        }
    ]
    return(
        <div style={{ margin: "auto", padding: "0"}}>
            <h2 style={{padding: "0rem 2rem", marginBottom: "10px", float: "left"}}>QR Code</h2>
            <br/>
            <div style={{padding: "0rem 2rem", display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
                {
                    qrCode.map(i => {
                        console.log(i)
                        return(
                            <div style={{marginTop: '50px'}}>
                                <h3>{i.label}</h3>
                                <img src={i.path} alt="qrcode1" width={500}/>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}