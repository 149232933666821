import React, {useState, useRef} from 'react'
import config from '../../config.json'


export default function User_edit({user, reload}){
    const [up_status, set_up_status] = useState(false)
    const [up_depart, set_up_depart] = useState(user.depart)
    const [check_pass, set_check_pass] = useState(false)
    const [up_pass, set_up_pass] = useState("")
    const [up_pass2, set_up_pass2] = useState("")
    const sup_team = useRef(0)

    const update_submit = (e) => {
        e.preventDefault();
        document.body.classList.remove('disable_scroll');
        if(check_pass){
            if(up_pass !== up_pass2){
                alert("password ทั้งสองไม่ตรงกัน")
                return;
            }
            else if(up_pass == "" || up_pass2 == ""){
                alert("โปรดกรอก password ทั้งสองช่อง")
                return;
            }
        }
        else{
            set_up_pass('')
        }
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        var data = {
            mode: "user",
            id: user.id,
            pass: up_pass,
            depart: up_depart,
            config: check_pass,
            sup_team: sup_team.current
        }

        fetch(config.api_url, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status === 1) {
                alert("สำเร็จ")
                set_up_status(false)
                reload()
            }
        });
    }

    const delete_user = (e) => {
        e.preventDefault();
        document.body.classList.remove('disable_scroll');
        if(window.confirm("คุณแน่ใจที่จะลบ '"+ user.user +"'") === false) return;
        var json = {
            mode: "user",
            id: user.id
        }
        fetch(config.api_url, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(json)
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status === 1) {
                alert("สำเร็จ")
                set_up_status(false)
                reload()
            }
        });
    }

    function getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }
    function getHeight() {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        );
    }

    return(
        <>
        <button id="edit" onClick={() => {set_up_status(true); set_up_depart(user.depart); sup_team.current = user.sup_team; document.body.classList.add('disable_scroll');}}>แก้ไขข้อมูล</button>
            {
                up_status === false ? <></>
                :
                <>
                    <div className='Add_bg' onClick={ () => {set_up_status(false); document.body.classList.remove('disable_scroll');}} style={{top: window.scrollY, width: getWidth(), height: getHeight()}}></div>
                    <div className='Add' style={{top: (window.scrollY+(window.innerHeight*0.5)), left: (window.scrollX+(window.innerWidth*0.5)), translate: "-50% -50%"}}>
                        <h2>แก้ไขข้อมูล</h2>
                        <form style={{width: "60%"}} onSubmit={update_submit}>
                            <label>username</label>
                            <input type="text" value={user.user} disabled/>
                            <label>แผนก</label>
                            { user.id != 1
                                ?<select onChange={(e) => set_up_depart(e.target.value)} value={up_depart}>
                                    {
                                        Object.entries(config.user_depart).map((arr, i) => {
                                            if(i === 0) return
                                            return(
                                                <option value={i}>{arr[1]}</option>
                                            )
                                        })
                                    }
                                </select>
                                : <select disabled></select>
                            }
                            {
                                up_depart == 2
                                ? <>
                                    <label>support team</label>
                                    <select defaultValue={sup_team.current} onChange={val => sup_team.current = val.target.value}>
                                        <option value="1">Support team 1</option>
                                        <option value="2">Support team 2</option>
                                        <option value="3">Support team 3</option>
                                        <option value="5">Support team 5</option>
                                    </select>
                                </>
                                : <></>
                            }
                            <div>
                                <label>เปลี่ยนรหัสผ่าน</label>
                                <input type="checkbox" checked={check_pass} onChange={() => set_check_pass(!check_pass)}/>
                            </div>
                            {
                                check_pass === false ? <><div></div><div></div><div></div></>
                                : <>
                                <input type="password" onChange={(e) => set_up_pass(e.target.value)}/>
                                <labe>ยืนยันรหัสผ่าน</labe>
                                <input type="password" onChange={(e) => set_up_pass2(e.target.value)}/>
                                </>
                            }
                            <input id="submit" style={{marginRight: 'auto'}} type="submit" value="บันทึก"/>
                            {user.id != 1
                                ? <button onClick={delete_user} className='delete_user'>ลบข้อมูล</button>
                                : <></>
                            }
                        </form>
                    </div>
                </>
            }
        </>
    )
}