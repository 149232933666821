import React, {useState, useEffect} from 'react'
import config from '../../config.json'

export default function Load_poppup(props){
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    const [search, set_search] = useState("")
    const [update, set_update] = useState(0)

    const [data, set_data] = useState({})

    useEffect(() => {
        load()
    }, [update])

    const load = () => {
        const url = config.api_url+"/doc/get.php?mode=23&search="+search+"&token="+userToken
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            set_data(data)
        });
    }
    const load_detail = (id) => {
        const url = config.api_url+"/doc/get.php?mode=24&id="+id+"&token="+userToken
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then((response) => response.json())
        .then((data) => {
            //console.log(data)
            props.return(data)
        });
    }

    return(
        <div className='Load_popup' style={{border: "none", padding: "10px"}}>
            <input type="text" style={{width: "450px", display: "flex", margin: "auto"}} placeholder='ใช้เลขที่ในการค้นหา' value={search}
                onChange={ e => {
                    set_search(e.target.value);
                    set_update(old => old+1)
                }}
            />
            <div style={{overflowY: "scroll", height: "400px"}}>
                {
                    data == {}
                    ? <p>none</p>
                    : <>
                        <div className='Load_company_detail_head'>
                            <p>เลขที่</p>
                            <p>UHQ</p>
                            <p>บริษัท</p>
                        </div>
                        {
                            Object.entries(data).map((val, i) => {
                                return(
                                    <div className='Load_company_detail'
                                        onClick={e => {
                                            load_detail(val[1].id)
                                        }}
                                    >
                                        <p>{val[1].id}</p>
                                        <p style={{textOverflow: "ellipsis", overflow: "hidden"}}>{val[1].uhq}</p>
                                        <p>{val[1].company}</p>
                                    </div>
                                )
                            })
                        }
                    </>
                }
            </div>
        </div>
    )
}